import React from 'react';
import OrderReceived from '../features/order-received/order-received';
import { withNavbar } from '../hocs/withNavbar';

const OrderReceivedPage = () => {
  return (
    <>
      <OrderReceived />
    </>
  );
};

export default withNavbar(OrderReceivedPage);
