import React, { useReducer, useContext, createContext, useEffect } from 'react';
import { reducer, cartItemsTotalPrice, shouldPayDelivery } from './cart.reducer';
import { useStorage } from '../../utils/use-storage';
import {
    clearStorageIfExpired,
    setLocalStorageTimestamp
} from '../../utils/storage'; // Import the utility functions

const CartContext = createContext({});
const INITIAL_STATE = {
    isOpen: false,
    items: [],
    isRestaurant: false,
    coupon: null,
    temporalOrder: null,
    delivery: false,
};

const useCartActions = (initialCart = INITIAL_STATE) => {
    const [state, dispatch] = useReducer(reducer, initialCart);

    const addItemHandler = (item, quantity = 1, prodQuantity) => {
        dispatch({ type: 'ADD_ITEM', payload: { ...item, quantity, prodQuantity } });
    };

    const removeItemHandler = (item, quantity = 1) => {
        dispatch({ type: 'REMOVE_ITEM', payload: { ...item, quantity } });
    };

    const clearItemFromCartHandler = (item) => {
        dispatch({ type: 'CLEAR_ITEM_FROM_CART', payload: item });
    };

    const handleDelivery = (hasDelivery) => {
        dispatch({ type: 'HANDLE_DELIVERY', payload: hasDelivery });
    };

    const addTemporalOrder = (order) => {
        dispatch({ type: 'ADD_ORDER', payload: order });
    };

    const removeTemporalOrder = () => {
        dispatch({ type: 'REMOVE_ORDER' });
    };

    const clearCartHandler = () => {
        dispatch({ type: 'CLEAR_CART' });
    };
    const toggleCartHandler = () => {
        dispatch({ type: 'TOGGLE_CART' });
    };
    const couponHandler = (coupon) => {
        dispatch({ type: 'APPLY_COUPON', payload: coupon });
    };
    const removeCouponHandler = () => {
        dispatch({ type: 'REMOVE_COUPON' });
    };
    const rehydrateLocalState = (payload) => {
        dispatch({ type: 'REHYDRATE', payload });
    };
    const toggleRestaurant = () => {
        dispatch({ type: 'TOGGLE_RESTAURANT' });
    };
    const isInCartHandler = (id) => {
        return state.items?.some((item) => item.id === id);
    };
    const getItemHandler = (id) => {
        return state.items?.find((item) => item.id === id);
    };
    const getCartItemsPrice = () => cartItemsTotalPrice(state.items);
    const getCartItemsTotalPrice = () =>
        cartItemsTotalPrice(state.items, state.coupon, state.delivery);

    const shouldPayDeliveryCosts = () =>
        shouldPayDelivery(state.items, state.coupon, state.delivery);

    const getDiscount = () => {
        const total = cartItemsTotalPrice(state.items);
        const discount = state.coupon
            ? Math.floor((total * Number(state.coupon?.discountInPercent)) / 100)
            : 0;
        return discount;
    };
    const getItemsCount = state.items?.reduce(
        (acc, item) => acc + item.quantity,
        0
    );
    return {
        state,
        getItemsCount,
        rehydrateLocalState,
        addItemHandler,
        removeTemporalOrder,
        removeItemHandler,
        clearItemFromCartHandler,
        clearCartHandler,
        isInCartHandler,
        getItemHandler,
        addTemporalOrder,
        handleDelivery,
        toggleCartHandler,
        getCartItemsTotalPrice,
        getCartItemsPrice,
        shouldPayDeliveryCosts,
        couponHandler,
        removeCouponHandler,
        getDiscount,
        toggleRestaurant,
    };
};

export const CartProvider = ({ children }) => {
    const {
        state,
        rehydrateLocalState,
        getItemsCount,
        addItemHandler,
        removeItemHandler,
        clearItemFromCartHandler,
        clearCartHandler,
        isInCartHandler,
        addTemporalOrder,
        removeTemporalOrder,
        getItemHandler,
        toggleCartHandler,
        getCartItemsTotalPrice,
        couponHandler,
        shouldPayDeliveryCosts,
        removeCouponHandler,
        getCartItemsPrice,
        handleDelivery,
        getDiscount,
        toggleRestaurant,
    } = useCartActions();
    const { rehydrated, error } = useStorage(state, rehydrateLocalState);

    useEffect(() => {
        clearStorageIfExpired(); // Check and clear storage if expired
        setLocalStorageTimestamp(); // Set a new timestamp when the component mounts
    }, []);

    return (
        <CartContext.Provider
            value={{
                isOpen: state.isOpen,
                items: state.items,
                temporalOrder: state.temporalOrder,
                delivery: state.delivery,
                coupon: state.coupon,
                isRestaurant: state.isRestaurant,
                cartItemsCount: state.items?.length,
                itemsCount: getItemsCount,
                addItem: addItemHandler,
                removeItem: removeItemHandler,
                removeItemFromCart: clearItemFromCartHandler,
                clearCart: clearCartHandler,
                isInCart: isInCartHandler,
                addTemporalOrder: addTemporalOrder,
                removeTemporalOrder: removeTemporalOrder,
                getItem: getItemHandler,
                toggleCart: toggleCartHandler,
                handleDelivery: handleDelivery,
                calculatePrice: getCartItemsTotalPrice,
                shouldPayDelivery: shouldPayDeliveryCosts,
                calculateSubTotalPrice: getCartItemsPrice,
                applyCoupon: couponHandler,
                removeCoupon: removeCouponHandler,
                calculateDiscount: getDiscount,
                toggleRestaurant,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
