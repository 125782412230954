// export const cartItemsTotalPrice = (items, { discountInPercent = 0 } = {}) => {
export const cartItemsTotalPrice = (items, coupon = null, delivery = false) => {
	if (items === null || items.length === 0) return 0;
	const itemCost = items.reduce((total, item) => {
		if (item.salePrice) {
			return total + item.salePrice * item.quantity;
		}
		return total + item.price * item.quantity;
	}, 0);
	// const discountRate = 1 - discountInPercent;
	const discount = coupon
		? Math.floor((itemCost * Number(coupon.discountInPercent)) / 100)
		: 0;
	// itemCost * discountRate * TAX_RATE + shipping;
	// return itemCost * discountRate;
	if (delivery) {

		return itemCost - discount + 260;

	} else {
		return itemCost - discount;
	}
};

export const shouldPayDelivery = (items, coupon = null, delivery = false) => {
	if (items === null || items.length === 0) return 0;
	const itemCost = items.reduce((total, item) => {
		if (item.salePrice) {
			return total + item.salePrice * item.quantity;
		}
		return total + item.price * item.quantity;
	}, 0);
	// const discountRate = 1 - discountInPercent;
	const discount = coupon
		? Math.floor((itemCost * Number(coupon.discountInPercent)) / 100)
		: 0;
	// itemCost * discountRate * TAX_RATE + shipping;
	// return itemCost * discountRate;
	if (delivery) {
		return true;
	} else {
		return false;
	}
};

// cartItems, cartItemToAdd
const addItemToCart = (state, action) => {
	const { payload } = action;

	if (payload.quantity > 0) {
		const existingCartItemIndex = state.items.findIndex(
			(item) => item.id === payload.id
		);
		if (existingCartItemIndex > -1) {
			const newState = [...state.items];
			console.log(newState[existingCartItemIndex])
			if (payload.prodQuantity) {
				if (newState[existingCartItemIndex].quantity < payload.prodQuantity) {
					newState[existingCartItemIndex].quantity += payload.quantity;
				}
			} else if (
				newState[existingCartItemIndex].quantity <
				newState[existingCartItemIndex].prodQuantity
			) {
				newState[existingCartItemIndex].quantity += payload.quantity;
			}

			return newState;
		}
		return [...state.items, payload];
	}
};

// cartItems, cartItemToRemove
const removeItemFromCart = (state, action) => {
	return state.items.reduce((acc, item) => {
		if (item.id === action.payload.id) {
			const newQuantity = item.quantity - action.payload.quantity;

			return newQuantity > 0
				? [...acc, { ...item, quantity: newQuantity }]
				: [...acc];
		}
		return [...acc, item];
	}, []);
};

const clearItemFromCart = (state, action) => {
	return state.items.filter((item) => item.id !== action.payload.id);
};

export const reducer = (state, action) => {
	switch (action.type) {
		case "REHYDRATE":
			return { ...state, ...action.payload };
			break;
		case "TOGGLE_CART":
			return { ...state, isOpen: !state.isOpen };
			break;
		case "ADD_ITEM":
			return { ...state, items: addItemToCart(state, action) };
			break;
		case "REMOVE_ITEM":
			return { ...state, items: removeItemFromCart(state, action) };
			break;
		case "CLEAR_ITEM_FROM_CART":
			return { ...state, items: clearItemFromCart(state, action) };
			break;
		case "CLEAR_CART":
			return { ...state, items: [], delivery: false, coupon: null };
			break;
		case "HANDLE_DELIVERY":
			return { ...state, delivery: action.payload };
			break;
		case "ADD_ORDER":
			return { ...state, temporalOrder: action.payload };
			break;
		case "REMOVE_ORDER":
			return { ...state, temporalOrder: null };
			break;
		case "APPLY_COUPON":
			return { ...state, coupon: action.payload };
			break;
		case "REMOVE_COUPON":
			return { ...state, coupon: null };
			break;
		case "TOGGLE_RESTAURANT":
			return { ...state, isRestaurant: !state.isRestaurant };
			break;
		default:
			return state;
	}
};
