import CategoryPage from "../pages/Category";
import Checkout from "../pages/Checkout";
import OrderReceived from "../pages/OrderReceived";
import ProductPage from "../pages/ProductDetails";
import Profile from '../pages/Profile';
import Order from '../pages/Order';
import Terms from '../pages/Terms';
import FacebookCsv from '../pages/FacebookCsv';
import FacebookCsvDownload from '../pages/FacebookCsvDownload';

const routes = [
	{
		path: "/",
		exact: true,
		component: CategoryPage,
	},
	{
		path: "/grocery",
		exact: true,
		component: CategoryPage,
	},
	{
		path: "/facebook-csv-export",
		exact: true,
		component: FacebookCsv,
	},
	{
		path: "/facebook-csv-download",
		exact: true,
		component: FacebookCsvDownload,
	},
	{
		path: "/checkout",
		exact: true,
		component: Checkout,
	},
	{
		path: "/terms",
		exact: true,
		component: Terms,
	},
	{
		path: "/orders",
		exact: true,
		component: Order,
	},
	{
		path: "/profile",
		exact: true,
		component: Profile,
	},
	{
		path: "/order-received",
		exact: true,
		component: OrderReceived,
	},
	{
		path: "/product/:slug",
		exact: true,
		component: ProductPage,
	},
	// {
	//   path: '*',
	//   component: Error404,
	// },
];

export { routes };
