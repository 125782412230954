import React, {useRef} from "react";
import { SearchBox } from "../../components/search-box/search-box";
import { useAppState, useAppDispatch } from "../../contexts/app/app.provider";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

// interface Props {
//   minimal?: boolean;
//   showButtonText?: boolean;
//   onSubmit?: () => void;
//   [key: string]: unknown;
// }

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Search = ({ onSubmit, onScroll, ...props }) => {
	const searchTerm = useAppState("searchTerm");
	const dispatch = useAppDispatch();
	const router = useLocation();
	let history = useHistory();
	const currentQuery = useQuery();
	const intl = useIntl();

	const { pathname } = router;

	const cat = currentQuery.get("category");
	let newQuery = null;
	if (cat) {
		newQuery = `category=${cat}`;
	}

	const handleOnChange = (e) => {
		const { value } = e.target;
		dispatch({ type: "SET_SEARCH_TERM", payload: value });
	};

	const onSearch = (e) => {
		e.preventDefault();
		if(onScroll) {
			onScroll()
		}
		if (newQuery) {
			if (searchTerm.trim()) {
				history.replace({
					pathname: "/",
					search: `${newQuery}&text=${searchTerm}`,
				});
			} else {
				history.replace({
					search: `${newQuery}`,
				});
			}
		} else {
			if (searchTerm.trim()) {
				history.replace({
					pathname: "/",
					search: `text=${searchTerm}`,
				});
			} else {
				history.replace({
					pathname: "/",
				});
			}
		}
		dispatch({ type: "SET_SEARCH_TERM", payload: "" });
		if (onSubmit) {
			onSubmit();
		}
	};
	return (
		<SearchBox
			onEnter={onSearch}
			onChange={handleOnChange}
			value={searchTerm}
			name="search"
			placeholder={intl.formatMessage({
				id: "searchPlaceholder",
				defaultMessage: "Search your products from here",
			})}
			buttonText={intl.formatMessage({
				id: "searchButtonText",
				defaultMessage: "Search",
			})}
			{...props}
		/>
	);
};

export default withRouter(Search);
