import React from "react";
import {
	SingleOrderList,
	OrderListHeader,
	TrackID,
	Status,
	OrderMeta,
	Meta,
} from "./order-card.style";
import { FormattedMessage } from "react-intl";

import { CURRENCY } from "../../../../utils/constant";
import moment from "moment";

const OrderCard = ({
	orderId,
	onClick,
	className,
	status,
	date,
	deliveryTime,
	amount,
	deliveryMethod,
}) => {
	return (
		<>
			<SingleOrderList onClick={onClick} className={className}>
				<OrderListHeader>
					<TrackID>
						<FormattedMessage
							id="intlOrderCardTitleText"
							defaultMessage="Order"
						/>
					</TrackID>
					<Status>{status}</Status>
				</OrderListHeader>
				<OrderListHeader>
					<span>Id:</span>
					<span style={{ fontSize: "11px" }}>{orderId}</span>
				</OrderListHeader>

				<OrderMeta>
					<Meta>
						<FormattedMessage
							id="intlOrderCardDateText"
							defaultMessage="Order Date"
						/>
						: <span>{moment(date).format("DD/MM/YY")}</span>
					</Meta>
					<Meta>
						<FormattedMessage
							id="deliveryMethodText"
							defaultMessage="Order Date"
						/>
						:{" "}
						<span>{deliveryMethod == 2 ? "Delivery" : deliveryMethod == 1 ? "Retiro en local Carrasco" : "Retiro en local Pocitos"}</span>
					</Meta>
					<Meta>
						<FormattedMessage
							id="deliveryTimeTextAlt"
							defaultMessage="Delivery Time"
						/>
						: <span>{deliveryTime}</span>
					</Meta>
					<Meta className="price">
						<FormattedMessage
							id="intlOrderCardTotalText"
							defaultMessage="Total Price"
						/>
						:
						<span>
							{CURRENCY}
							{amount}
						</span>
					</Meta>
				</OrderMeta>
			</SingleOrderList>
		</>
	);
};

export default OrderCard;
