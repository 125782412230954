import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import Router from 'next/router';
import { Button } from "../../button/button";
import {
	ProductDetailsWrapper,
	ProductPreview,
	ProductInfo,
	ProductInfoFull,
	ProductTitlePriceWrapper,
	ProductTitle,
	BackButton,
	ProductWeight,
	ProductDescription,
	ButtonText,
	ProductMeta,
	ProductMetaAlt,
	ProductCartWrapper,
	ProductPriceWrapper,
	ProductPrice,
	SalePrice,
	ProductCartBtn,
	MetaSingle,
	MetaItem,
	RelatedItems,
} from "./product-details-one.style";
import { LongArrowLeft } from "../../../assets/icons/LongArrowLeft";
import { CartIcon } from "../../../assets/icons/CartIcon";
import ReadMore from "../../truncate/truncate";
import CarouselWithCustomDots from "../../multi-carousel/multi-carousel";
import Products from "../../product-grid/product-list/product-list";
import { CURRENCY } from "../../../utils/constant";
import { FormattedMessage } from "react-intl";
import { useLocale } from "../../../contexts/language/language.provider";
import { useCart } from "../../../contexts/cart/use-cart";
import { Counter } from "../../counter/counter";
import offer from "../../../assets/images/promotion/offer-4.png";
import delivery from "../../../assets/images/notes/delivery.svg";
import store from "../../../assets/images/notes/store.svg";
import clock from "../../../assets/images/notes/clock.svg";
import info from "../../../assets/images/notes/information.svg";
import { FOOD_CLASSIFICATION } from "../../../assets/images/food-classification/foodClassification";
import ReactPixel from 'react-facebook-pixel';

// type ProductDetailsProps = {
//   product: any;
//   deviceType: {
//     mobile: boolean;
//     tablet: boolean;
//     desktop: boolean;
//   };
// };

const ProductDetails = ({ product, deviceType, showSantander }) => {
	const { isRtl } = useLocale();
	const { addItem, removeItem, isInCart, getItem } = useCart();
	const data = product;

	const handleAddClick = (e) => {
		e.stopPropagation();
		addItem(data, 1, data?.quantity);
		ReactPixel.track('AddToCart');
	};

	const handleRemoveClick = (e) => {
		e.stopPropagation();
		removeItem(data);
	};

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
		ReactPixel.track('ViewContent');
	}, []);

	if (!product) return null;

	const normalizedPossibleCats = product.categories?.length
		? product.categories.map((c) => c.id)
		: [];

	return (
		<>
			<ProductDetailsWrapper className="product-card" dir="ltr">
				{!isRtl && (
					<ProductPreview>
						<CarouselWithCustomDots
							items={product.gallery}
							deviceType={deviceType}
						/>
					</ProductPreview>
				)}

				<ProductInfo dir={isRtl ? "rtl" : "ltr"}>
					<ProductTitlePriceWrapper>
						<ProductTitle>{product.title}</ProductTitle>
						<ProductPriceWrapper>
							{product.discountInPercent ? (
								<SalePrice>
									{CURRENCY}
									{product.price}
								</SalePrice>
							) : null}

							<ProductPrice>
								{CURRENCY}
								{product.salePrice ? product.salePrice : product.price}
							</ProductPrice>
						</ProductPriceWrapper>
					</ProductTitlePriceWrapper>

					<ProductWeight>{product.unit}</ProductWeight>

					<ProductMetaAlt>
						<MetaSingle>
							{product?.categories?.length
								? product?.categories?.map((item) => (
										<Link
											to={`/${product.type.toLowerCase()}?category=${
												item.slug
											}`}
											key={`link-${item.id}`}
										>
											{
												<a>
													<MetaItem>{item.title}</MetaItem>
												</a>
											}
										</Link>
								  ))
								: null}
						</MetaSingle>
					</ProductMetaAlt>

					<ProductDescription>
						<h6>Descripción</h6>
						<ReadMore character={600}>{product.description}</ReadMore>
					</ProductDescription>

					<div style={{ display: "flex" }}>
						{product.qualifications && product.qualifications.length ? (
							<div style={product.foodClassification ? { width: "50%" } : {}}>
								<ProductDescription>
									<h6>Cualidades a destacar</h6>
									<ul>
										{product.qualifications &&
											product.qualifications.map((q) => (
												<li>
													<ReadMore character={600}>- {q.title}</ReadMore>
												</li>
											))}
									</ul>
								</ProductDescription>
							</div>
						) : null}

						{product.foodClassification && product.foodClassification.length ? (
							<div style={{ width: "50%" }}>
								<ProductDescription>
									<h6>Clasificación alimenticia</h6>
									<div style={{ display: "flex" }}>
										{product.foodClassification.map((p) => (
											<img
												style={{ height: "30px", marginRight: "5px" }}
												src={FOOD_CLASSIFICATION[p.name]}
											/>
										))}
									</div>
								</ProductDescription>
							</div>
						) : null}
					</div>

					<ProductCartWrapper>
						<ProductCartBtn>
							{!isInCart(data.id) || data?.quantity == 0 ? (
								<Button
									className={
										data.quantity == 0 ? "cart-button-disabled" : "cart-button"
									}
									variant="secondary"
									borderRadius={100}
									onClick={handleAddClick}
								>
									{data?.quantity > 0 && <CartIcon mr={2} />}
									<ButtonText>
										{data?.quantity == 0 ? (
											"Agotado"
										) : (
											<FormattedMessage
												id="addCartButton"
												defaultMessage="Añadir al carrito"
											/>
										)}
									</ButtonText>
								</Button>
							) : (
								<Counter
									value={getItem(data.id).quantity}
									onDecrement={handleRemoveClick}
									onIncrement={handleAddClick}
								/>
							)}
						</ProductCartBtn>
					</ProductCartWrapper>

					{product.productionTime > 24 ? (
						<ProductMetaAlt>
							<div
								style={{
									display: "flex",
									padding: "5px",
									borderRadius: "10px",
									backgroundColor: "#9abb511f",
								}}
							>
								<img style={{ height: "30px" }} src={clock} />
								<p style={{ marginLeft: "10px", color: "#424561" }}>
									<strong>Tiempo de producción: </strong>
									{`Este producto lleva ${product.productionTime}hs de producción.
									Considérelo debido a que su fecha de entrega se verá ajustada en base a esto.`}
								</p>
							</div>
						</ProductMetaAlt>
					) : null}

					<ProductMetaAlt>
						<h5 style={{ color: "#424561" }}>Consideraciones:</h5>
						<div style={{ display: "flex", marginTop: "10px" }}>
							<img style={{ height: "30px" }} src={delivery} />
							<p style={{ marginLeft: "10px", color: "#424561" }}>
								Delivery: Se hacen envíos de <strong>Lunes a Sábado. </strong>
								Todos los pedidos realizados antes de las{" "}
								<strong>14hs</strong>, serán entregados en las próximas{" "}
								<strong>48hs</strong>. En casos especiales que se especificará,
								la elaboración del producto puede demorar hasta{" "}
								<strong>72hs</strong>.
							</p>
						</div>

						<div style={{ display: "flex", marginTop: "10px" }}>
							<img style={{ height: "30px" }} src={store} />
							<p style={{ marginLeft: "10px", color: "#424561" }}>
								Pick up: Todos los pedidos realizados antes de las{" "}
								<strong>14hs</strong>, se podrán retirar al siguiente día a
								partir de las <strong>11am</strong> en la Boutique, de lo
								contrario será a partir de <strong>48hs</strong>.
								{/* Pick up: <strong>Próximamente</strong>. */}
							</p>
						</div>

						<div style={{ display: "flex", marginTop: "10px" }}>
							<img style={{ height: "30px" }} src={info} />
							<p style={{ marginLeft: "10px", color: "#424561" }}>
								<strong>*Nota:</strong> El tiempo de entrega{" "}
								<strong>puede variar</strong>, si usted necesita su producto
								antes de lo previsto, se coordinará una vez concretada la compra
								llamando al <strong>2601 8601</strong>.
							</p>
						</div>
					</ProductMetaAlt>

					{/* <ProductMetaAlt>
						<h5 style={{ color: "#ba5065" }}>Atención:</h5>
						<div style={{ display: "flex", marginTop: "10px" }}>
							<p style={{ color: "#ba5065" }}>
								Los pedidos realizados desde el <strong>29/12/20</strong> hasta el{" "}
								<strong>02/01/21</strong> serán entregados a partir del <strong>05/01/21.  </strong>
								Se coordinará por teléfono. Gracias por la comprensión.
							</p>
						</div>
					</ProductMetaAlt> */}

					{showSantander && (
						<ProductMeta>
							<img src={offer} style={{ height: "150px" }} />
							<p style={{ color: "rgb(115 115 115)" }}>
								*El descuento se verá reflejado en el Estado de Cuenta.
							</p>
						</ProductMeta>
					)}
				</ProductInfo>

				{isRtl && (
					<ProductPreview>
						<BackButton>
							<Button
								title="Back"
								intlButtonId="backBtn"
								iconPosition="left"
								size="small"
								style={{
									backgroundColor: "#ffffff",
									border: "1px solid #f1f1f1",
									color: "#77798c",
								}}
								icon={<LongArrowLeft />}
								// onClick={Router.back}
							/>
						</BackButton>

						<CarouselWithCustomDots
							items={product.gallery}
							deviceType={deviceType}
						/>
					</ProductPreview>
				)}
			</ProductDetailsWrapper>

			<RelatedItems>
				<h2>
					<FormattedMessage
						id="intlReletedItems"
						defaultMessage="Related Items"
					/>
				</h2>
				<Products
					type={product.type.toLowerCase()}
					deviceType={deviceType}
					loadMore={false}
					fetchLimit={10}
					modeRelated
					possibleCategories={normalizedPossibleCats}
				/>
			</RelatedItems>
		</>
	);
};

export default ProductDetails;
