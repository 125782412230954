import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import RadioGroup from "../../components/radio-group/radio-group";
import RadioCard from "../../components/radio-card/radio-card";
import { Button } from "../../components/button/button";
import UpdateAddress from "../../components/address-card/address-card";
import { handleModal } from "../../features/checkouts/checkout-modal";
import { ProfileContext } from "../../contexts/profile/profile.context";
import useUser from "../../data/use-user";
import { CardHeader } from "../../components/card-header/card-header";
import { ButtonGroup } from "../../components/button-group/button-group";
import { Box } from "../../components/box";
import { Plus } from "../../assets/icons/PlusMinus";
import { LOCATIONS } from "../../constants/locations";

const Address = ({
	increment = false,
	flexStart = false,
	icon = false,
	buttonProps = {
		size: "big",
		variant: "outlined",
		type: "button",
		className: "add-button",
	},
	isDelivery = false,
}) => {
	const { deleteAddress } = useUser();

	const findLocation = (item) => {
		if (item) {
			const found = LOCATIONS.find((i) => i.id == item);
			if (found) {
				return `- ${found.name}`;
			} else {
				return "";
			}
		} else {
			return "";
		}
	};

	const {
		state: { address, id },
		dispatch,
	} = useContext(ProfileContext);

	const handleOnDelete = async (item) => {
		dispatch({ type: "DELETE_ADDRESS", payload: item.id });
		try {
			await deleteAddress(id);
			toast.success("Se ha eliminado la dirección exitosamente", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
		} catch (e) {
			toast.error("Ha ocurrido un error, inténtelo nuevamente", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
		}
	};
	return (
		<>
			<CardHeader increment={increment}>
				<FormattedMessage
					id={
						isDelivery
							? "checkoutDeliveryAddress"
							: "checkoutDeliveryAddressAlt"
					}
					defaultMessage="Select Your Delivery Address"
				/>
			</CardHeader>
			<ButtonGroup flexStart={flexStart}>
				<RadioGroup
					items={address}
					component={(item) => (
						<RadioCard
							id={item.id}
							key={item.id}
							title={item.name}
							content={`${item.info} ${findLocation(item.location)}`}
							name="address"
							checked={item.type === "primary"}
							onChange={() =>
								dispatch({
									type: "SET_PRIMARY_ADDRESS",
									payload: item.id?.toString(),
								})
							}
							onEdit={() => handleModal(UpdateAddress, item)}
							onDelete={() => handleOnDelete(item)}
						/>
					)}
					secondaryComponent={
						<Button
							{...buttonProps}
							onClick={() => handleModal(UpdateAddress, "add-address-modal")}
							style={{ borderStyle: "dashed" }}
						>
							{icon && (
								<Box mr={2}>
									<Plus width="10px" />
								</Box>
							)}
							<FormattedMessage
								id="addAddressBtn"
								defaultMessage="Add Address"
							/>
						</Button>
					}
				/>
			</ButtonGroup>
			<div style={{ display: "flex" }}>
				<p style={{ marginLeft: "10px", color: "#424561", fontSize: "14px" }}>
				<i>	Ésta información es requerida por <strong>VISA</strong>.</i>
				</p>
			</div>
		</>
	);
};

export default Address;
