// product card for general
import React from "react";
import Image from "../../image/image";
import { Button } from "../../button/button";
import {
	ProductCardWrapper,
	ProductImageWrapper,
	ProductInfo,
	DiscountPercent,
	ButtonText,
} from "../product-card.style";
import { useCart } from "../../../contexts/cart/use-cart";
import { Counter } from "../../counter/counter";
import { cartAnimation } from "../../../utils/cart-animation";
import { FormattedMessage } from "react-intl";
import { CartIcon } from "../../../assets/icons/CartIcon";
import ReactPixel from 'react-facebook-pixel';

const ProductCard = ({
	title,
	image,
	weight,
	price,
	salePrice,
	discountInPercent,
	cartProducts,
	addToCart,
	updateCart,
	value,
	categories,
	currency,
	onChange,
	increment,
	decrement,
	data,
	deviceType,
	showLabel,
	onClick,
	...props
}) => {
	const { addItem, removeItem, getItem, isInCart, items } = useCart();
	const handleAddClick = (e) => {
		e.stopPropagation();
		addItem(data, 1, data?.quantity);
		if (!isInCart(data.id)) {
			cartAnimation(e);
		}
		ReactPixel.track('AddToCart');
	};
	const handleRemoveClick = (e) => {
		e.stopPropagation();
		removeItem(data);
	};

	let catLabels = [];
	if (showLabel && categories?.length) {
		categories.forEach((c) => catLabels.push(c.title));
	}

	var isMobile = false;
	// device detection
	if (
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
			navigator.userAgent
		) ||
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
			navigator.userAgent.substr(0, 4)
		)
	) {
		isMobile = true;
	}

	return (
		<ProductCardWrapper onClick={onClick} className="product-card">
			<ProductImageWrapper>
				<Image
					url={image}
					className="product-image"
					style={{ position: "relative" }}
					alt={title}
				/>
				{discountInPercent ? (
					<>
						<DiscountPercent>{discountInPercent}%</DiscountPercent>
					</>
				) : (
					""
				)}
			</ProductImageWrapper>
			<ProductInfo>
				<h3 className="product-title">{title}</h3>
				<span className="product-weight">{weight}</span>
				{catLabels && catLabels.length ? (
					<p
						className="product-weight"
						style={{ color: "#0d113640", marginTop: "10px" }}
					>
						{catLabels.join(" | ")}
					</p>
				) : null}
				<div className="product-meta">
					<div className="productPriceWrapper">
						{discountInPercent ? (
							<span className="discountedPrice">
								{currency}
								{price}
							</span>
						) : (
							""
						)}

						<span className="product-price">
							{currency}
							{salePrice ? salePrice : price}
						</span>
					</div>

					{!isInCart(data.id) || data?.quantity == 0 ? (
						<Button
							type="button"
							className={
								data.quantity == 0 ? "cart-button-disabled" : "cart-button"
							}
							variant="secondary"
							disabled={data?.quantity == 0}
							borderRadius={100}
							onClick={handleAddClick}
						>
							{data?.quantity > 0 && <CartIcon mr={2} />}
							<ButtonText>
								{data?.quantity == 0 ? (
									"Agotado"
								) : (
									<FormattedMessage
										id={!isMobile ? "addCartButton" : "addCartButtonMobile"}
										defaultMessage="Añadir al carrito"
									/>
								)}
							</ButtonText>
						</Button>
					) : (
						<Counter
							value={getItem(data.id).quantity}
							onDecrement={handleRemoveClick}
							onIncrement={handleAddClick}
						/>
					)}
				</div>
			</ProductInfo>
		</ProductCardWrapper>
	);
};

export default ProductCard;
