import React from 'react';
import { Img } from 'react-image';
import placeholder from './product-placeholder.png';
import noPhoto from '../../assets/images/products/noPhoto.png';

const Placeholder = () => <img src={placeholder} alt="product img loader" />;

export default function Image({
  url,
  alt = 'placeholder',
  unloader,
  loader,
  className,
  style,
}) {
  return (
    <img style={style}
    src={url || noPhoto}       className={className}
    ></img>
    // <Img
    //   draggable={false}
    //   style={style}
    //   src={url}
    //   alt={alt}
    //   loader={<Placeholder />}
    //   unloader={<Placeholder />}
    //   className={className}
    // />
  );
}
