import React from 'react';
import { useLocation, withRouter, useHistory } from "react-router-dom";
import NoResultSvg from './notFound.png';
import {
  NoResultWrapper,
  ImageWrapper,
  ButtonWrapper,
} from './no-result.style';
import { ArrowPrev } from '../../assets/icons/ArrowPrev';
import { Button } from '../button/button';
// import { SearchContext } from '../../contexts/search/search.context';
import { FormattedMessage } from 'react-intl';

// type NoResultFoundProps = {
//   id?: string;
// };

const NoResultFound = ({ id }) => {
  // const { dispatch } = React.useContext(SearchContext);
  let history = useHistory();
  const router = useLocation();

	const { pathname } = router;

  function onClickButton() {
    // dispatch({
    //   type: 'RESET',
    // });
    const href = pathname;

    history.push(href, href, { shallow: true });
  }
  return (
    <NoResultWrapper id={id}>
      <h3>
        <FormattedMessage
          id="noResultFound"
          defaultMessage="Sorry, No result found :("
        />
      </h3>

      <ImageWrapper>
        <img src={NoResultSvg} alt="No Result" />
      </ImageWrapper>

      <ButtonWrapper>
        <div onClick={onClickButton}>
          <Button>
            <ArrowPrev /> Volver
          </Button>
        </div>
      </ButtonWrapper>
    </NoResultWrapper>
  );
};

export default withRouter(NoResultFound);
