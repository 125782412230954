import React, { useContext } from "react";
import { openModal } from "@redq/reuse-modal";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Scrollbars } from "react-custom-scrollbars";
import Drawer from "../../components/drawer/drawer";
import { Button } from "../../components/button/button";
import NavLink from "../../components/nav-link/nav-link";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { AuthContext } from "../../contexts/auth/auth.context";
import { ProfileContext } from "../../contexts/profile/profile.context";
import AuthenticationForm from "../../features/authentication-form";
import {
	HamburgerIcon,
	DrawerContentWrapper,
	DrawerClose,
	DrawerProfile,
	LogoutView,
	LoginView,
	UserAvatar,
	UserDetails,
	DrawerMenu,
	DrawerMenuItem,
	UserOptionMenu,
} from "./header.style";
import UserImage from "../../assets/images/user.jpg";
import {
	AUTHORIZED_MENU_ITEMS,
	MOBILE_DRAWER_MENU,
	PROFILE_PAGE,
	TERMS_AND_SERVICES_PAGE,
} from "../../site-settings/site-navigation";
import { useAppState, useAppDispatch } from "../../contexts/app/app.provider";
import { auth } from "../../firebase";
import avatar from "../../assets/images/user/user-1.svg";

const MobileDrawer = () => {
	let history = useHistory();
	const isDrawerOpen = useAppState("isDrawerOpen");
	const dispatch = useAppDispatch();
	const {
		authState: { isAuthenticated },
		authDispatch,
  } = useContext(AuthContext);

	// Toggle drawer
	const toggleHandler = React.useCallback(() => {
		dispatch({
			type: "TOGGLE_DRAWER",
		});
	}, [dispatch]);

	const handleLogout = () => {
		if (typeof window !== "undefined") {
			localStorage.removeItem("access_token");
			auth.signOut();
			authDispatch({ type: "SIGN_OUT" });
			history.push("/");
		}
	};

	const signInOutForm = () => {
		dispatch({
			type: "TOGGLE_DRAWER",
		});

		authDispatch({
			type: "SIGNIN",
		});

		openModal({
			show: true,
			overlayClassName: "quick-view-overlay",
			closeOnClickOutside: true,
			component: AuthenticationForm,
			closeComponent: "",
			config: {
				enableResizing: false,
				disableDragging: true,
				className: "quick-view-modal",
				width: 458,
				height: "auto",
			},
		});
	};

	return (
		<Drawer
			width="316px"
			drawerHandler={
				<HamburgerIcon>
					<span />
					<span />
					<span />
				</HamburgerIcon>
			}
			open={isDrawerOpen}
			toggleHandler={toggleHandler}
			closeButton={
				<DrawerClose>
					<CloseIcon />
				</DrawerClose>
			}
		>
			<Scrollbars autoHide>
				<DrawerContentWrapper>
					<DrawerProfile>
						{isAuthenticated ? (
							<LoginView>
								<UserAvatar>
									<img src={avatar} alt="user_avatar" />
								</UserAvatar>
								<UserDetails>
									<h3>{"Mi perfil"}</h3>
								</UserDetails>
							</LoginView>
						) : (
							<LogoutView>
								<Button variant="primary" onClick={signInOutForm}>
									<FormattedMessage
										id="mobileSignInButtonText"
										defaultMessage="join"
									/>
								</Button>
							</LogoutView>
						)}
					</DrawerProfile>

					<DrawerMenu>
						{MOBILE_DRAWER_MENU.map((item) => (
							<DrawerMenuItem key={item.id}>
								<NavLink
									onClick={toggleHandler}
									href={item.href}
									label={item.defaultMessage}
									intlId={item.id}
									className="drawer_menu_item"
								/>
							</DrawerMenuItem>
						))}
						
						{
							isAuthenticated && (
								AUTHORIZED_MENU_ITEMS.map((item) => (
									<DrawerMenuItem key={item.id}>
										<NavLink
											onClick={toggleHandler}
											href={item.href}
											label={item.defaultMessage}
											intlId={item.id}
											className="drawer_menu_item"
										/>
									</DrawerMenuItem>
								))
							)
						}
					</DrawerMenu>

					{isAuthenticated && (
						<UserOptionMenu>
							<DrawerMenuItem>
								<NavLink
									href={PROFILE_PAGE}
									label="Your Account Settings"
									className="drawer_menu_item"
									intlId="navlinkAccountSettings"
								/>
							</DrawerMenuItem>
							<DrawerMenuItem>
								<div onClick={handleLogout} className="drawer_menu_item">
									<span className="logoutBtn">
										<FormattedMessage
											id="navlinkLogout"
											defaultMessage="Logout"
										/>
									</span>
								</div>
							</DrawerMenuItem>
						</UserOptionMenu>
					)}
				</DrawerContentWrapper>
			</Scrollbars>
		</Drawer>
	);
};

export default MobileDrawer;
