import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import { ThemeProvider } from "styled-components";
import { Helmet } from 'react-helmet';

import styled from "styled-components";
import { defaultTheme } from "./site-settings/site-theme/default";
import { AppProvider } from "./contexts/app/app.provider";
import { AuthProvider } from "./contexts/auth/auth.provider";
import { LanguageProvider } from "./contexts/language/language.provider";
import { CartProvider } from "./contexts/cart/use-cart";
import { useMedia } from "./utils/use-media";
import AppLayout from "./layouts/app-layout";
import { Button } from "./components/button/button";
import CategoryPage from "./pages/Category";
import ReactPixel from "react-facebook-pixel";
import WhatsAppWidget from "react-whatsapp-widget";

// External CSS import here
import "react-whatsapp-widget/dist/index.css";
import "rc-drawer/assets/index.css";
import "rc-table/assets/index.css";
import "rc-collapse/assets/index.css";
import "react-multi-carousel/lib/styles.css";
import "./components/multi-carousel/multi-carousel.style.css";
import "@redq/reuse-modal/lib/index.css";
import { GlobalStyle } from "./assets/styles/global.style";

import { Router } from "./routes";

// Language translation messages
import { messages } from "./site-settings/site-translation/messages";
import "typeface-lato";
import "typeface-poppins";

import { clearStorageIfExpired, setLocalStorageTimestamp } from "./utils/storage";

const customStyles = {
	overlay: {
		zIndex: 1000000000000,
		background: '#52525296',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '50%',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
};

Modal.setAppElement('#root');

const WhatsappContainer = styled.div`
	position: fixed;
	z-index: 10000000;
	bottom: 100px;
	right: -28px;
	background: rgb(79 206 93);
	border-radius: 20px;
	height: 95px;
	width: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	> div ._1bpcM {
		margin-bottom: 0px !important;
		@media (max-width: 500px) {
			margin-right: 20px !important;
		}
	}
`;

let subtitle;


const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: false, // enable logs
};
ReactPixel.init("821354585135328", {}, options);
ReactPixel.pageView(); // For tracking page view



// need to provide types
function App({ Component, pageProps }) {
	const mobile = useMedia("(max-width: 580px)");
	const tablet = useMedia("(max-width: 991px)");
	const desktop = useMedia("(min-width: 992px)");
	const [message, setMessage] = useState('')
	const [modalIsOpen, setIsOpen] = useState(false);

	function openModal() {
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`https://nora-rey-backend.herokuapp.com/api/holidays/`
				);
				if (response.data && response.data.holidaysArray && response.data.holidaysArray.length) {
					const { holidaysArray } = response.data;
					const currentDate = holidaysArray[0];
					const start = new Date(currentDate.start);
					const end = new Date(currentDate.end);
					const message = currentDate.message;
					const today = new Date();
					var diffDaysStart = parseInt((start - today) / (1000 * 60 * 60 * 24), 10);

					if (diffDaysStart < 2 || (today >= start && today < end)) {
						//Should show modal
						setIsOpen(true);
						setMessage(message)
					}
				}
			} catch (error) {
				// setError(error);
			}
		};

		fetchData();
	}, []);

	// Check and clear localStorage every 24 hours
	useEffect(() => {
		clearStorageIfExpired();
		setLocalStorageTimestamp();
	}, []);

	return (
		<ThemeProvider theme={defaultTheme}>
			<LanguageProvider messages={messages}>
				<CartProvider>
					<AppProvider>
						<AuthProvider>
							<Router>
								{/* <AppLayout> */}
								{/* 
                <CategoryPage
                  {...pageProps}
                  deviceType={{ mobile, tablet, desktop }}
                /> */}
								{/* </AppLayout> */}

							</Router>
							<WhatsappContainer
							>
								<WhatsAppWidget
									phoneNumber="59893938681"
									companyName={"Nora Rey Traiteur"}
									textReplyTime={"Te responderemos en menos de 24hrs."}
									message={"Hola! 👋🏼 \n\nCómo podemos ayudarte?"}
									sendButton={"Enviar"}
								/>
							</WhatsappContainer>
							<Modal
								isOpen={modalIsOpen}
								onRequestClose={closeModal}
								style={customStyles}
								contentLabel="Example Modal"
							>
								<h2 ref={(_subtitle) => (subtitle = _subtitle)}>Atención</h2>
								<div style={{ padding: 20 }}>
									<p>{message}</p>
									<p style={{ fontStyle: 'italic', marginTop: 5 }}>El equipo de Nora Rey.</p>
								</div>
								<Button onClick={closeModal} style={{ width: '150px' }}>Aceptar</Button>
							</Modal>

							<GlobalStyle />
						</AuthProvider>
					</AppProvider>
				</CartProvider>
			</LanguageProvider>
		</ThemeProvider>
	);
}

export default App;
