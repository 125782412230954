import useSWR from "swr";
import Fuse from "fuse.js";
import { useState } from "react";
const options = {
	// isCaseSensitive: false,
	// includeScore: false,
	shouldSort: true,
	// includeMatches: false,
	// findAllMatches: false,
	// minMatchCharLength: 1,
	// location: 0,
	threshold: 0.3,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
	minMatchCharLength: 2,
	keys: ["title"],
};
function search(list, pattern) {
	const fuse = new Fuse(list, options);

	return fuse.search(pattern).map((current) => current.item);
}
// import productFetcher from 'utils/api/product';
const productFetcher = (url) => fetch(url).then((res) => res.json());
// interface Props {
//   type: string;
//   text?: any;
//   category?: any;
//   offset?: number;
//   limit?: number;
// }
export default function useProducts(variables) {
	const { type, text, category, modeRelated } = variables ?? {};
	const { data, mutate, error } = useSWR(
		"https://nora-rey-backend.herokuapp.com/api/products/with-categories",
		productFetcher
	);
	const loading = !data && !error;
	let newData = [];
	let related = [];
	if (data) {
		newData = data.data;
		related = data.relatedCategories;
	}
	let products = newData?.filter((current) =>
		type ? current.type === type : true
	);
	if (category) {
		products = products?.filter((product) =>
			product.categories.find(
				(category_item) =>
					category_item.slug === category || category === "todos"
			)
		);

		if (category !== "todos") {
			products = products.sort((a, b) => {
				if (a.title && b.title) {
					if (a.title < b.title) {
						return -1;
					}
					if (a.title > b.title) {
						return 1;
					}
					return 0;
				} else if (a.title && !b.title) {
					return -1;
				} else if (b.title && !a.title) {
					return 1;
				} else {
					return 0;
				}
			});
		}
	}
	if (text) {
		products = search(products, text);
	}

	return {
		loading,
		error,
		data: products,
		mutate,
		related,
	};
}
