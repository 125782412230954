import React from "react";
import {
	ProductsRow,
	ProductsCol,
	ButtonWrapper,
	LoaderWrapper,
	LoaderItem,
	ProductCardWrapper,
} from "./product-list.style";
import { useHistory, useLocation } from "react-router-dom";
import { CURRENCY } from "../../../utils/constant";
import Placeholder from "../../placeholder/placeholder";
import Fade from "react-reveal/Fade";
import NoResultFound from "../../no-result/no-result";
import useProducts from "../../../data/use-products";
import ErrorMessage from "../../error-message/error-message";
import GeneralCard from "../../product-card/product-card-one/product-card-one";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const Products = ({
	deviceType,
	fetchLimit = 20,
	loadMore = true,
	type,
	possibleCategories = null,
	modeRelated = false,
}) => {
	let history = useHistory();
	let query = useQuery();
	const { data, error, related, santanderActive, loading } = useProducts({
		type,
		text: query.get("text"),
		category: query.get("category"),
	});

	let relatedData = data;

	if (error) return <ErrorMessage message={error.message} />;
	if (loading) {
		return (
			<LoaderWrapper>
				<LoaderItem>
					<Placeholder uniqueKey="1" />
				</LoaderItem>
				<LoaderItem>
					<Placeholder uniqueKey="2" />
				</LoaderItem>
				<LoaderItem>
					<Placeholder uniqueKey="3" />
				</LoaderItem>
				<LoaderItem>
					<Placeholder uniqueKey="4" />
				</LoaderItem>
				<LoaderItem>
					<Placeholder uniqueKey="5" />
				</LoaderItem>
				<LoaderItem>
					<Placeholder uniqueKey="6" />
				</LoaderItem>
				<LoaderItem>
					<Placeholder uniqueKey="7" />
				</LoaderItem>
				<LoaderItem>
					<Placeholder uniqueKey="8" />
				</LoaderItem>
			</LoaderWrapper>
		);
	}

	if (data && data.length === 0) {
		return <NoResultFound />;
	}

	if (
		modeRelated &&
		data &&
		related &&
		related.length &&
		possibleCategories &&
		possibleCategories.length
	) {
		let categoriesRelationships = [];

		related.forEach((r) => {
			if (possibleCategories.includes(r.id)) {
				categoriesRelationships = categoriesRelationships.concat(r.related);
			}
		});

		relatedData = data.filter((prod) => {
			let inside = false;
			if (prod.categories?.length) {
				prod.categories.forEach((p) => {
					if (categoriesRelationships.includes(p.id)) {
						inside = true;
					}
				});
			}
			return inside;
		});

		if (relatedData && relatedData.length == 0) {
			relatedData = data;
		}

		relatedData = shuffle(relatedData)
	}

	const renderCard = (productType, props) => {
		return (
			<GeneralCard
				showSantander={santanderActive}
				title={props.title}
				description={props.description}
				image={props.image}
				weight={props.unit}
				currency={CURRENCY}
				categories={props.categories}
				price={props.price}
				salePrice={props.salePrice}
				discountInPercent={props.discountInPercent}
				data={props}
				deviceType={deviceType}
				showLabel={modeRelated}
				onClick={() => history.push(`/product/${props.slug}`)}
			/>
		);
	};

	
	return (
		<>
			<ProductsRow>
				{modeRelated && relatedData && relatedData.length 
					? relatedData.slice(0, 5).map((item, index) => (
							<ProductsCol
								key={index}
								style={
									type === "book" ? { paddingLeft: 0, paddingRight: 1 } : {}
								}
							>
								<ProductCardWrapper>
									<Fade
										duration={800}
										delay={index * 10}
										style={{ height: "100%" }}
									>
										{renderCard(type, item)}
									</Fade>
								</ProductCardWrapper>
							</ProductsCol>
					  ))
					: relatedData && relatedData.length ? relatedData.map((item, index) => (
							<ProductsCol
								key={index}
								style={
									type === "book" ? { paddingLeft: 0, paddingRight: 1 } : {}
								}
							>
								<ProductCardWrapper>
									<Fade
										duration={800}
										delay={index * 10}
										style={{ height: "100%" }}
									>
										{renderCard(type, item)}
									</Fade>
								</ProductCardWrapper>
							</ProductsCol>
					  )) : null}
			</ProductsRow>
		</>
	);
};
export default Products;
