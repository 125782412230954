import useSWR from "swr";

import axios from "axios";

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function useCoupon() {
	const { data, mutate, error } = useSWR(
		"https://nora-rey-backend.herokuapp.com/api/coupons",
		fetcher
	);

	const verifyCoupon = async (coupon_code) => {
    console.log(coupon_code, "coupon_code");
    try {
      const res = await axios.get(
        `https://nora-rey-backend.herokuapp.com/api/coupons/valid-coupon/${coupon_code}`
      );
      return res.data?.data;
    } catch (e) {
      return null;
    }
	};

	return {
		error,
		data,
		mutate,
		verifyCoupon,
	};
}
