import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

// type NavLinkProps = {
//   router: any;
//   href: string;
//   label: string;
//   intlId?: string;
//   icon?: React.ReactNode;
//   className?: string;
//   iconClass?: string;
//   dynamic?: boolean;
//   onClick?: () => void;
// };

const Icon = styled.span`
	min-width: 16px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NavLink = ({
	href,
	label,
	intlId,
	router,
	icon,
	className,
	onClick,
	iconClass,
	dynamic,
	pathname,
}) => {
	let location = useLocation();

	const isCurrentPath = location.pathname === href;
	return (
		<div onClick={onClick} className={className ? className : ""}>
			{dynamic ? (
				<Link href={"/[type]"} as={href}>
					<a
						className={isCurrentPath ? " current-page" : ""}
						style={{ display: "flex", alignItems: "center" }}
					>
						{icon ? <Icon className={iconClass}>{icon}</Icon> : ""}

						<span className="label">
							{intlId ? (
								<FormattedMessage
									id={intlId ? intlId : "defaultNavLinkId"}
									defaultMessage={label}
								/>
							) : (
								label
							)}
						</span>
					</a>
				</Link>
			) : (
				<Link
					to={href}
					className={isCurrentPath ? " current-page" : ""}
					style={{ display: "flex", alignItems: "center" }}
				>
					{icon ? <Icon className={iconClass}>{icon}</Icon> : ""}

					<span className="label">
						{intlId ? (
							<FormattedMessage
								id={intlId ? intlId : "defaultNavLinkId"}
								defaultMessage={label}
							/>
						) : (
							label
						)}
					</span>
				</Link>
			)}
		</div>
	);
};

export default withRouter(NavLink);
