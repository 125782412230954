import React from 'react';
import { LogoBox, LogoImage } from './logo.style';
import { useLocation, withRouter, useHistory } from "react-router-dom";

// type LogoProps = {
//   imageUrl: string;
//   alt: string;
//   onClick?: () => void;
// };

const Logo = ({ imageUrl, alt, onClick }) => {
	let history = useHistory();

  function onLogoClick() {
    history.push('/');
    if (onClick) {
      onClick();
    }
  }
  return (
    <LogoBox onClick={onLogoClick}>
      <LogoImage src={imageUrl} alt={alt} />
    </LogoBox>
  );
};

export default Logo;
