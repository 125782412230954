export const LOCATIONS = [
	{
		id: 1,
		name: "Bañados de Carrasco",
	},
	{
		id: 2,
		name: "Barra de Carrasco",
	},
	{
		id: 3,
		name: "Barrio Sur",
	},
	{
		id: 4,
		name: "Buceo",
	},
	{
		id: 5,
		name: "Carrasco",
	},
	{
		id: 6,
		name: "Carrasco Norte",
	},
	{
		id: 7,
		name: "Centro",
	},
	{
		id: 8,
		name: "Ciudad Vieja",
	},
	{
		id: 9,
		name: "Cordón",
	},
	{
		id: 10,
		name: "El Pinar",
	},
	{
		id: 11,
		name: "La Blanqueada",
	},
	{
		id: 12,
		name: "Lagomar",
	},
	{
		id: 13,
		name: "La Tahona",
	},
	{
		id: 14,
		name: "Malvín",
	},
	{
		id: 15,
		name: "Palermo",
	},
	{
		id: 16,
		name: "Parque Batlle",
	},
	{
		id: 17,
		name: "Parque Rodó",
	},
	{
		id: 18,
		name: "Paso Carrasco",
	},
	{
		id: 19,
		name: "Pocitos",
	},
	{
		id: 20,
		name: "Prado",
	},
	{
		id: 21,
		name: "Punta Gorda",
	},
	{
		id: 22,
		name: "Punta Carretas",
	},
	{
		id: 23,
		name: "Solymar",
	},
	{
		id: 24,
		name: "Tres Cruces",
	},
	{
		id: 25,
		name: "Unión",
	},
];

export const CITIES = [
	{
		id: 1,
		name: "Montevideo",
	},
	{
		id: 2,
		name: "Canelones",
	},
];
