import React, { useContext } from "react";
import * as Yup from "yup";
import { withFormik, FormikProps, Form } from "formik";
import { toast } from "react-toastify";
import { closeModal } from "@redq/reuse-modal";
import TextField from "../forms/text-field";
import { Button } from "../button/button";
// import { useMutation } from '@apollo/client';
// import { UPDATE_ADDRESS } from 'graphql/mutation/address';
import { FieldWrapper, Heading } from "./address-card.style";
import { ProfileContext } from "../../contexts/profile/profile.context";
import { FormattedMessage } from "react-intl";
import { LOCATIONS, CITIES } from "../../constants/locations";
import useUser from "../../data/use-user";

const FormEnhancer = withFormik({
	// Transform outer props into form values
	mapPropsToValues: (props) => {
		return {
			id: props.item.id || null,
			name: props.item.name || "",
			info: props.item.info || "",
			location: props.item.location || 1,
			city: props.item.city || 1,
		};
	},
	validationSchema: Yup.object().shape({
		name: Yup.string().required("Este campo es requerido"),
		info: Yup.string().required("Este campo es requerido"),
		location: Yup.string().required("Este campo es requerido"),
		city: Yup.string().required("Este campo es requerido"),
	}),
	handleSubmit: (values) => {
		console.log(values, "values");
		// do submitting things
	},
});

const UpdateAddress = (props) => {
	const {
		isValid,
		item,
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
	} = props;

	const addressValue = {
		id: values.id,
		type: "secondary",
		name: values.name,
		location: values.location,
		info: values.info,
		city: values.city,
	};
	const { state, dispatch } = useContext(ProfileContext);

	const { addOrUpdateAddress } = useUser();

	const handleSubmit = async () => {
		if (
			values && 
			values.info?.trim() &&
			values.name?.trim() &&
			values.location &&
			values.city
		) {
			try {
				await addOrUpdateAddress(state?.id, values);
				dispatch({ type: "ADD_OR_UPDATE_ADDRESS", payload: addressValue });
				toast.success("Se han modificado sus datos exitosamente", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			} catch (e) {
				toast.error("Ha ocurrido un error, inténtelo nuevamente", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}

			closeModal();
		}
	};
	return (
		<Form>
			<Heading>
				{item && item.id ? "Editar dirección" : "Agregar nueva dirección"}
			</Heading>
			<FieldWrapper>
				<TextField
					id="name"
					name="name"
					type="text"
					placeholder="Nombre (Ej: casa)"
					error={touched.name && errors.name}
					value={values.name}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
			</FieldWrapper>

			<FieldWrapper>
				<TextField
					id="info"
					name="info"
					as="textarea"
					placeholder="Dirección (Ej. Rivera 7010)"
					error={touched.info && errors.info}
					value={values.info}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
			</FieldWrapper>

			<FieldWrapper>
				<select
					id="location"
					name="location"
					as="location"
					error={touched.location && errors.location}
					value={values.location ? Number(values.location) : null}
					onChange={handleChange}
					onBlur={handleBlur}
					style={{
						height: "auto",
						outline: "none",
						padding: "18px",
						color: "gray",
						border: "1px solid #f1f1f1",
						borderRadius: "6px",
						fontSize: "15px",
					}}
				>
					{LOCATIONS.map((l) => (
						<option
							style={{
								fontSize: "13px",
							}}
							value={l.id}
						>
							{l.name}
						</option>
					))}
				</select>
			</FieldWrapper>

			<FieldWrapper>
				<select
					id="city"
					name="city"
					as="city"
					error={touched.city && errors.city}
					value={values.city ? Number(values.city) : null}
					onChange={handleChange}
					onBlur={handleBlur}
					style={{
						height: "auto",
						outline: "none",
						padding: "18px",
						color: "gray",
						border: "1px solid #f1f1f1",
						borderRadius: "6px",
						fontSize: "15px",
					}}
				>
					{CITIES.map((l) => (
						<option
							style={{
								fontSize: "13px",
							}}
							value={l.id}
						>
							{l.name}
						</option>
					))}
				</select>
			</FieldWrapper>

			<Button
				onClick={handleSubmit}
				type="submit"
				style={{ width: "100%", height: "44px" }}
			>
				<FormattedMessage id="savedAddressId" defaultMessage="Save Address" />
			</Button>
		</Form>
	);
};

export default FormEnhancer(UpdateAddress);
