// utils/storage.js
export const getLocalStorageTimestamp = () => {
    return localStorage.getItem('localStorageTimestamp');
};

export const setLocalStorageTimestamp = () => {
    const now = new Date().getTime();
    localStorage.setItem('localStorageTimestamp', now);
};

export const clearStorageIfExpired = () => {
    const timestamp = getLocalStorageTimestamp();
    const now = new Date().getTime();
    const twentyFourHours = 24 * 60 * 60 * 1000;

    if (timestamp && now - timestamp > twentyFourHours) {
        localStorage.clear();
        setLocalStorageTimestamp(); // Reset the timestamp after clearing storage
    }
};
