export const siteTermsAndServices = {
	title: "Términos y condiciones",
	date: "01/01/2020",
	content: [
		{
			id: "1",
			title: "Cambios y reembolsos",
			description: `<p>No se realizan cambios ni devoluciones.</p> <p>Si usted se encuentra insatisfecho con su producto puede contactarse con nosotros <strong><a href='mailto:norareyweb@gmail.com'>norareyweb@gmail.com</a></strong>.</p>`,
		},
		{
			id: "2",
			title: "Métodos de pago",
			description: `<p>Aceptamos todas las tarjetas emitidas por <strong>VISA</strong>.</p>`,
		},
		{
			id: "3",
			title: "Horarios y fechas",
			description: `<p>Delivery: Todos los pedidos realizados antes de las
        <strong>14hs</strong>, serán entregados de Lunes a Sábado en las próximas
        <strong>48hs</strong>. Luego de las 14.00hrs y en casos especiales que se especificará, la
        elaboración del producto puede demorar hasta <strong>72hs</strong>.</p>
				<p>Pick up: <strong>Carrasco (Schroeder 6464) / Pocitos (26 de Marzo 1153).</strong></p>
        <p>Los domingos la Boutique se encuentra cerrada y no se realizan envíos.</p>
        `,
		},
		{
			id: "4",
			title: "Zonas de envío",
      description: `<p>Realizamos envíos dentro de las zonas: Carrasco, Carrasco Norte, Paso Carrasco, Barra de Carrasco, Bañados de Carrasco, Malvín, Punta Gorda, Pocitos, Punta Carretas, Buceo, Barrio Sur, Cordón, Palermo, La Blanqueada, Parque Rodó, Parque Batlle, Ciudad Vieja, Centro, Tres Cruces, Prado, Unión, La Tahona, Lagomar, Solymar y El Pinar. </p>
      <p>El envío tiene un costo de $260</p>
     <p>Si usted realizó un pedido con envío y no se encuentra dentro de las zonas establecidas deberá retirarlo en el local. Contactarse primero con <strong><a href="mailto:norareyweb@gmail.com">norareyweb@gmail.com</a></strong>.</p>
      `,
		},
	],
};
