const loadSiemprePago = (callback) => {
	const existingScript = document.getElementById("siemprePago");

	if (!existingScript) {
		const script = document.createElement("script");
		script.src =
			"https://api.siemprepago.com/v1/Scripts/PWCheckout.js?key=3XFxoyS-zeyeTT_k4IeB92_I56ONV7HQ";
		script.id = "siemprePago";
		document.body.appendChild(script);

		script.onload = () => {
			if (callback) callback();
		};
	}
	if (existingScript && callback){
    callback();
  } 
};

export default loadSiemprePago;
