import { LOCATIONS, CITIES } from "../constants/locations";
import moment from "moment";

export const getAddress = (address) => {
	let finalAddress = "";
	if (address) {
		finalAddress = address.info;
		const neighboardhood = LOCATIONS.find((l) => l.id == address.location);
		if (neighboardhood) {
			finalAddress += `, ${neighboardhood.name}`;
		}
	}
	return finalAddress;
};

export const getAddressDetail = (address) => {
	let finalAddress = "";
	if (address) {
		finalAddress = address.info;
	}
	return finalAddress;
};

export const getCity = (address) => {
	let finalAddress = "";
	if (address) {
		const city = CITIES.find((l) => l.id == address.city);
		if (city) {
			finalAddress = city.name;
		}
	}
	return finalAddress;
};

export const getDeliveryMethod = (schedule) => {
	let selected = "";
	if (schedule?.length) {
		selected = schedule.find((s) => s.type == "primary");
		if (selected) {
			return selected.id;
		}
	}
};

export const getTentativeDelivery = (items) => {
	let limit = 0;

	const today = new Date();
	if (today.getHours() > 14) {
		limit = 72;
	} else {
		limit = 48;
	}

	//Now check if one item is more than the limit
	let futureDate = null;

	if (items && items.length > 0) {
		items.forEach((i) => {
			const { productionTime } = i;
			if (productionTime) {
				const normalizedTime = Number(productionTime);
				if (normalizedTime > limit || normalizedTime == limit) {
					limit = normalizedTime;
				}
			}
		});

		futureDate = new Date();
		let days = 0;
		if (limit == 48) {
			days = 2;
		} else if (limit == 24) {
			days = 1;
		} else if (limit == 72) {
			days = 3;
		}
		futureDate.setDate(today.getDate() + days);

		if (futureDate.getDay() == 0) {
			//Its Sunday, we have to move it to Monday.
			futureDate.setDate(futureDate.getDate() + 1);
		}
		if (futureDate) {
			return moment(futureDate).format("DD/MM/YY");
		}
	} else {
		return "No hay información";
	}
};

export const getWarning = (items, isDelivery) => {
	console.log(items)
	let limit = 0;
	let showWarning = false;

	const today = new Date();
	if (isDelivery) {
		if (today.getHours() > 14) {
			limit = 72;
		} else {
			limit = 48;
		}
	} else {
		if (today.getHours() > 14) {
			limit = 48;
		} else {
			limit = 24;
		}
	}

	//Now check if one item is more than the limit
	if (items && items.length > 0) {
		items.forEach((i) => {
			const { productionTime } = i;
			if (productionTime) {
				const normalizedTime = Number(productionTime);
				if (normalizedTime > limit) {
					limit = normalizedTime;
					showWarning = true;
				}
			}
		});
	}

	return { limit: limit, showWarning: showWarning };
};

export const getTentativePickup = (items) => {
	let limit = 0;

	const today = new Date();
	if (today.getHours() > 14) {
		limit = 48;
	} else {
		limit = 24;
	}

	//Now check if one item is more than the limit
	let futureDate = null;

	if (items && items.length > 0) {
		items.forEach((i) => {
			const { productionTime } = i;
			if (productionTime) {
				const normalizedTime = Number(productionTime);
				if (normalizedTime > limit) {
					limit = normalizedTime;
				}
			}
		});

		futureDate = new Date();
		let days = 0;
		if (limit == 48) {
			days = 2;
		} else if (limit == 24) {
			days = 1;
		} else if (limit == 72) {
			days = 3;
		}
		futureDate.setDate(today.getDate() + days);

		if (futureDate.getDay() == 0) {
			//Its sunday, we have to move it to Monday.
			futureDate.setDate(futureDate.getDate() + 1);
		}
		if (futureDate) {
			return moment(futureDate).format("DD/MM/YY");
		}
	} else {
		return "No hay información";
	}
};

export const calculateWarningDate = (items, isDelivery) => {};
