import React from 'react';
export const Plate = ({
  color = '#0D1137',
  width = '30px',
  height = '30px',
}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"  space="preserve" width={width} height={height}><g><g>
	<g>
		<g>
			<path d="M107.672,315.408c-2.507,3.638-1.591,8.62,2.047,11.127c3.638,2.507,8.62,1.591,11.127-2.047     c0.053-0.076,0.104-0.154,0.154-0.232c27.808-42.09,74.889-67.408,125.336-67.4c4.418,0,8-3.582,8-8s-3.582-8-8-8     C190.527,240.842,138.439,268.847,107.672,315.408z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
			<path d="M504,416.856h-48v-9.664c0.186-51.829-20.95-101.453-58.448-137.232c-3.107-3.142-8.172-3.17-11.314-0.064     c-3.142,3.107-3.17,8.172-0.064,11.314c0.094,0.095,0.19,0.188,0.289,0.278c34.345,32.775,53.704,78.23,53.536,125.704v9.664H72     v-9.664c0.106-96.239,78.097-174.23,174.336-174.336h19.328c14.861-0.01,29.662,1.872,44.048,5.6     c4.233,1.267,8.691-1.137,9.958-5.37c1.267-4.233-1.137-8.691-5.37-9.958c-0.194-0.058-0.391-0.109-0.589-0.152     c-11.049-2.85-22.332-4.7-33.712-5.528v-24.592c0-4.418-3.582-8-8-8h-32c-4.418,0-8,3.582-8,8v24.592     c-99.207,7.598-175.869,90.246-176,189.744v9.664H8c-4.418,0-8,3.582-8,8s3.582,8,8,8h496c4.418,0,8-3.582,8-8     S508.418,416.856,504,416.856z M248,200.856h16v16h-16V200.856z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
			<path d="M338.672,178.8C346.12,185.448,352,190.696,352,201.408c0,10.712-5.88,15.96-13.328,22.648     c-11.322,7.912-18.244,20.706-18.672,34.512c0,4.418,3.582,8,8,8s8-3.582,8-8c0-10.712,5.88-15.96,13.328-22.608     c11.329-7.924,18.251-20.733,18.672-34.552c-0.421-13.817-7.343-26.623-18.672-34.544c-7.448-6.648-13.328-11.896-13.328-22.6     c0-10.704,5.88-15.944,13.328-22.592c11.329-7.913,18.252-20.715,18.672-34.528c0-4.418-3.582-8-8-8s-8,3.582-8,8     c0,10.696-5.88,15.936-13.328,22.584c-11.331,7.916-18.254,20.721-18.672,34.536C320.422,158.078,327.344,170.882,338.672,178.8z     " data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
			<path d="M386.672,178.8C394.12,185.448,400,190.696,400,201.408c0,10.712-5.88,16-13.328,22.616     c-11.331,7.919-18.254,20.726-18.672,34.544c0,4.418,3.582,8,8,8s8-3.582,8-8c0-10.712,5.88-15.96,13.328-22.608     c11.329-7.924,18.251-20.733,18.672-34.552c-0.421-13.817-7.343-26.623-18.672-34.544c-7.448-6.648-13.328-11.896-13.328-22.6     c0-10.704,5.88-15.944,13.328-22.592c11.329-7.913,18.252-20.715,18.672-34.528c0-4.418-3.582-8-8-8s-8,3.582-8,8     c0,10.696-5.88,15.936-13.328,22.584c-11.331,7.916-18.254,20.721-18.672,34.536C368.422,158.078,375.344,170.882,386.672,178.8z     " data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
			<path d="M434.672,178.8C442.12,185.448,448,190.696,448,201.408c0,10.712-5.88,16-13.328,22.616     c-11.331,7.919-18.254,20.726-18.672,34.544c0,4.418,3.582,8,8,8s8-3.582,8-8c0-10.712,5.88-15.96,13.328-22.608     c11.329-7.924,18.251-20.733,18.672-34.552c-0.421-13.817-7.343-26.623-18.672-34.544c-7.448-6.648-13.328-11.896-13.328-22.6     c0-10.704,5.88-15.944,13.328-22.592c11.329-7.913,18.252-20.715,18.672-34.528c0-4.418-3.582-8-8-8s-8,3.582-8,8     c0,10.696-5.88,15.936-13.328,22.584c-11.331,7.916-18.254,20.721-18.672,34.536C416.422,158.078,423.344,170.882,434.672,178.8z     " data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
		</g>
	</g>
</g></g> </svg>
 );
};
