import groceryImage from '../assets/images/banner/grocery-alt.jpg';

export const sitePages = {
  grocery: {
    page_title: 'Nora Rey - Boutique Online',
    page_description: 'Grocery Details',
    banner_title_id: 'groceriesTitle',
    banner_description_id: 'groceriesSubTitle',
    banner_image_url: groceryImage,
  }
};
