import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Modal } from "@redq/reuse-modal";
import Checkout from "../features/checkouts/checkout-two/checkout-two";
import { ProfileProvider } from "../contexts/profile/profile.provider";
import ErrorMessage from "../components/error-message/error-message";
import useUser from "../data/use-user";
import { withNavbar } from "../hocs/withNavbar";
import { auth } from "../firebase";
import axios from "axios";
import {
	SpinnerContainer,
	Spinner,
} from "../features/user-profile/order/order.style";

const CheckoutPage = ({ deviceType }) => {
	const [data, setData] = useState(null);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);
	let history = useHistory();

	useEffect(() => {
		const fetchData = async () => {
			try {
				auth.onAuthStateChanged(async function (user) {
					if (user) {
						const result = await axios(
							`https://nora-rey-backend.herokuapp.com/api/users/${user.uid}`
						);
						const { data } = result;
						setData(data?.user);
						setLoading(false);
					} else {
						// No user is signed in.
						setLoading(false);
						history.push('/');
					}
				});
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	if (error) return <ErrorMessage message={error.message} />;

	if (!data && !loading) {
		return <Redirect to="/" />;
	}

	if (loading)
		return (
			<SpinnerContainer>
				<Spinner></Spinner>
			</SpinnerContainer>
		);

	const token = "true";

	return (
		<>
			<ProfileProvider initData={data}>
				<Modal>
					<Checkout token={token} deviceType={deviceType} />
				</Modal>
			</ProfileProvider>
		</>
	);
};

export default withNavbar(CheckoutPage);
