import OfferOne from '../assets/images/promotion/offer-6.png';
import OfferThree from '../assets/images/promotion/offer-5.png';
import OfferTwo from '../assets/images/promotion/offer-4.png';

export const siteOffers = [
  {
    id: '1',
    imgSrc: OfferOne,
    alt: 'Offer 1',
  },
  {
    id: '2',
    imgSrc: OfferTwo,
    alt: 'Offer 2',
  },
  {
    id: '3',
    imgSrc: OfferThree,
    alt: 'Offer 3',
  },
];
