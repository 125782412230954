import React, { useContext, useState } from "react";
import {
	Wrapper,
	Container,
	LogoWrapper,
	Heading,
	SubHeading,
	// Input,
	Button,
	Divider,
	LinkButton,
	Offer,
} from "./authentication-form.style";
import { closeModal } from '@redq/reuse-modal';
import { FormattedMessage, useIntl } from "react-intl";
import { AuthContext } from "../../contexts/auth/auth.context";
import { Input } from "../../components/forms/input";
import { FIREBASE_ERRORS } from "../../constants/firebaseMessages";
import { auth } from "../../firebase";

export default function ForgotPasswordModal() {
	const [errorMsg, setError] = useState("");
	const [email, setEmail] = useState("");
	const { authDispatch } = useContext(AuthContext);
	const intl = useIntl();
	const toggleSignInForm = () => {
		authDispatch({
			type: "SIGNIN",
		});
	};

	const resetPwCallback = async (e) => {
		e.preventDefault();

		if (email) {
			if (typeof window !== "undefined") {
				try {

					auth
						.sendPasswordResetEmail(email)
						.then(function () {
							setError('Se ha enviado el correo electrónico.')
							// Email sent.
							setTimeout(() => {
								closeModal();

							}, 1000);
						})
						.catch(function (error) {
							var errorCode = error.code;
							var errorMessage = error.message;
							const customError = FIREBASE_ERRORS[errorCode];
							if (customError) {
								setError(customError);
							} else {
								setError("Ha ocurrido un error.");
							}
						});
				} catch (error) {
					var errorCode = error.code;
					const customError = FIREBASE_ERRORS[errorCode];
					if (customError) {
						setError(customError);
					} else {
						setError("Ha ocurrido un error.");
					}
				}
			}
		} else {
			setError("Ingresa un correo electrónico.");
		}
	};

	return (
		<Wrapper>
			<Container style={{ paddingBottom: 30 }}>
				<Heading>
					<FormattedMessage
						id="forgotPassText"
						defaultMessage="Forgot Password"
					/>
				</Heading>

				<SubHeading>
					<FormattedMessage
						id="sendResetPassText"
						defaultMessage="We'll send you a link to reset your password"
					/>
				</SubHeading>
				<form onSubmit={resetPwCallback}>
					<Input
						type="email"
						placeholder={intl.formatMessage({
							id: "emailAddressPlaceholder",
							defaultMessage: "Email Address or Contact No.",
						})}
						onChange={(e) => setEmail(e.target.value)}
						height="48px"
						required
						backgroundColor="#F7F7F7"
						mb="10px"
					/>

					<Button
						variant="primary"
						size="big"
						style={{ width: "100%" }}
						type="submit"
					>
						<FormattedMessage
							id="resetPasswordBtn"
							defaultMessage="Reset Password"
						/>
					</Button>
					<p>{`${errorMsg}`}</p>
				</form>
				<Divider></Divider>
				<Offer style={{ padding: "20px 0 0" }}>
					<FormattedMessage id="backToSign" defaultMessage="Back to" />{" "}
					<LinkButton onClick={toggleSignInForm}>
						<FormattedMessage id="loginBtnText" defaultMessage="Login" />
					</LinkButton>
				</Offer>
			</Container>
		</Wrapper>
	);
}
