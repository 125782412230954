const url = "https://nora-rey-backend.herokuapp.com";
export async function getAllProducts() {
	const products = await fetch(`${url}/api/products`);
	return await products.json();
}
export async function getProductBySlug(slug) {
	const products = await fetch(`${url}/api/products/with-categories`);
	const { data, santanderDiscountActive, relatedCategories } = await products.json();
	return {
		found: data.find((current) => current.slug === slug),
		santanderActive: santanderDiscountActive,
		relatedCategories: relatedCategories
	};
}
