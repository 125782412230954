export const ORDER_STATUS = {
	DELIVERY: {
		confirmed: 1,
		received: 2,
	},
	PICKUP: {
		confirmed: 1,
		received: 2,
	},
};
