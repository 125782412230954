import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import OrderReceivedWrapper, {
	OrderReceivedContainer,
	OrderInfo,
	OrderDetails,
	TotalAmount,
	BlockTitle,
	Text,
	InfoBlockWrapper,
	InfoBlock,
	ListItem,
	ListTitle,
	ListDes,
} from "./order-received.style";
import { FormattedMessage } from "react-intl";
import { useCart } from "../../contexts/cart/use-cart";
import moment from "moment";
import ReactPixel from "react-facebook-pixel";

const OrderReceived = (props) => {
	const { temporalOrder, delivery, removeTemporalOrder, clearCart } = useCart();

	useEffect(() => {
		if (temporalOrder) {
			ReactPixel.track("Purchase", {
				value: temporalOrder?.amount,
				currency: "UYU",
			});
		}
		return () => {
			clearCart();
			removeTemporalOrder();
		};
	}, []);

	let itemCount = 0;

	if (
		temporalOrder &&
		temporalOrder.products &&
		temporalOrder.products.length > 0
	) {
		temporalOrder.products.forEach((i) => (itemCount += i.quantity));
	}

	if (!temporalOrder) {
		clearCart();
		return <Redirect to="/" />;
	}

	return (
		<OrderReceivedWrapper>
			<OrderReceivedContainer>
				<Link to="/" className="home-btn">
					<FormattedMessage id="backHomeBtn" defaultMessage="Back to Home" />
				</Link>

				<OrderInfo>
					<BlockTitle>
						<FormattedMessage
							id="orderReceivedText"
							defaultMessage="Order Received"
						/>
					</BlockTitle>

					<Text>
						<FormattedMessage
							id="orderReceivedSuccess"
							defaultMessage="Thank you. Your order has been received"
						/>
					</Text>

					<InfoBlockWrapper>
						<InfoBlock>
							<Text bold className="title">
								<FormattedMessage
									id="orderNumberText"
									defaultMessage="Order Number"
								/>
							</Text>
							<Text>{temporalOrder?.id}</Text>
						</InfoBlock>

						<InfoBlock>
							<Text bold className="title">
								<FormattedMessage id="orderDateText" defaultMessage="Date" />
							</Text>
							<Text>{moment(new Date()).format("DD/MM/YY")}</Text>
						</InfoBlock>

						<InfoBlock>
							<Text bold className="title">
								<FormattedMessage id="totalText" defaultMessage="Total" />
							</Text>
							<Text>${temporalOrder?.amount}</Text>
						</InfoBlock>

						{/* <InfoBlock>
              <Text bold className="title">
                <FormattedMessage
                  id="paymenMethodText"
                  defaultMessage="Payment Method"
                />
              </Text>
              <Text>
                <FormattedMessage
                  id="paymentMethodName"
                  defaultMessage="Cash on delivery"
                />
              </Text>
            </InfoBlock> */}
					</InfoBlockWrapper>
				</OrderInfo>

				<OrderDetails>
					<BlockTitle>
						<FormattedMessage
							id="orderDetailsText"
							defaultMessage="Order Details"
						/>
					</BlockTitle>

					<ListItem>
						<ListTitle>
							<Text bold>
								<FormattedMessage
									id="totalItemText"
									defaultMessage="Total Item"
								/>
							</Text>
						</ListTitle>
						<ListDes>
							<Text>{itemCount}</Text>
						</ListDes>
					</ListItem>

					<ListItem>
						<ListTitle>
							<Text bold>
								<FormattedMessage
									id="deliveryMethodText"
									defaultMessage="Delivery Time"
								/>
							</Text>
						</ListTitle>
						<ListDes>
							<Text>
								{temporalOrder?.deliveryMethod == 2
									? "Delivery"
									: temporalOrder?.deliveryMethod == 3 ? "Retiro en tienda Pocitos" : "Retiro en tienda Carrasco"}
							</Text>
						</ListDes>
					</ListItem>

					<ListItem>
						<ListTitle>
							<Text bold>
								<FormattedMessage
									id="orderTimeText"
									defaultMessage="Order Time"
								/>
							</Text>
						</ListTitle>
						<ListDes>
							<Text>{temporalOrder?.deliveryTime}</Text>
						</ListDes>
					</ListItem>

					<ListItem>
						<ListTitle>
							<Text bold>
								<FormattedMessage
									id="deliveryTimeText"
									defaultMessage="Delivery Time"
								/>
							</Text>
						</ListTitle>
						<ListDes>
							<Text>
								{temporalOrder?.deliveryMethod == 2
									? "10.00 - 15.00"
									: "10.00 - 20.00 "}
							</Text>
						</ListDes>
					</ListItem>

					{delivery && (
						<ListItem>
							<ListTitle>
								<Text bold>
									<FormattedMessage
										id="deliveryLocationText"
										defaultMessage="Delivery Location"
									/>
								</Text>
							</ListTitle>
							<ListDes>
								<Text>{temporalOrder?.deliveryAddress}</Text>
							</ListDes>
						</ListItem>
					)}
				</OrderDetails>

				<TotalAmount>
					<BlockTitle>
						<FormattedMessage
							id="totalAmountText"
							defaultMessage="Total Amount"
						/>
					</BlockTitle>

					<ListItem>
						<ListTitle>
							<Text bold>
								<FormattedMessage id="subTotal" defaultMessage="Sub total" />
							</Text>
						</ListTitle>
						<ListDes>
							<Text>${temporalOrder?.subtotal}</Text>
						</ListDes>
					</ListItem>

					<ListItem>
						<ListTitle>
							<Text bold>
								<FormattedMessage id="discount" defaultMessage="Descuento" />
							</Text>
						</ListTitle>
						<ListDes>
							<Text>${temporalOrder?.discount}</Text>
						</ListDes>
					</ListItem>

					{/* <ListItem>
            <ListTitle>
              <Text bold>
                <FormattedMessage
                  id="paymenMethodText"
                  defaultMessage="Payment Method"
                />
              </Text>
            </ListTitle>
            <ListDes>
              <Text>Cash On Delivery</Text>
            </ListDes>
          </ListItem> */}

					<ListItem>
						<ListTitle>
							<Text bold>
								<FormattedMessage
									id="paymentMethodName"
									defaultMessage="Delivery Charge"
								/>
							</Text>
						</ListTitle>
						<ListDes>
							<Text>${temporalOrder?.deliveryFee}</Text>
						</ListDes>
					</ListItem>

					<ListItem>
						<ListTitle>
							<Text bold>
								<FormattedMessage id="totalText" defaultMessage="Total" />
							</Text>
						</ListTitle>
						<ListDes>
							<Text>${temporalOrder?.amount}</Text>
						</ListDes>
					</ListItem>
				</TotalAmount>
			</OrderReceivedContainer>
		</OrderReceivedWrapper>
	);
};

export default OrderReceived;
