import React from 'react';
export const Empanadas = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg"  version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"  width="512px" height="512px"><g><g>
	<g>
		<path d="M494.961,400.723c13.5-29.273,0.59-65.009-28.691-78.804c2.371-28.528-16.148-56.07-46.011-62.95    c-4.69-1.084-8.587-4.352-10.462-8.807c-10.53-24.998-36.393-39.103-62.188-35.914c-4.762,0.645-9.598-1.157-12.88-4.703    c-18.329-19.822-47.544-24.317-70.789-12.441c-4.308,2.198-11.35,2.198-15.657,0c-23.998-12.245-52.956-6.843-70.789,12.441    c-3.282,3.546-8.074,5.319-12.88,4.703c-25.526-3.209-51.591,10.762-62.188,35.914c-1.876,4.454-5.773,7.722-10.477,8.807    c-29.815,6.868-48.366,34.349-45.996,62.95c-30.94,14.558-41.71,50.571-28.691,78.804C6.472,411.594,0.03,428.294,0.03,442.675    c0,39.551,37.66,67.221,73.734,56.561c118.632-34.567,246.033-34.567,364.693,0c0.038,0.011,0.081,0.005,0.119,0.016    c34.131,9.947,73.415-15.369,73.415-56.577C511.992,428.555,505.882,411.727,494.961,400.723z M386.161,349.613    c-6.601,4.951-16.005,3.654-21.013-2.989c-53.94-71.919-164.175-71.866-218.075,0c-5.012,6.623-14.39,7.942-21.013,2.989    c-6.623-4.982-7.956-14.39-2.989-21.013c65.97-87.682,199.852-88.023,266.078,0C394.118,335.224,392.784,344.631,386.161,349.613z    " data-original="#000000"  data-old_color="#000000" fill="#0D1137"/>
	</g>
</g><g>
	<g>
		<path d="M494.961,220.665c13.674-29.654,0.853-64.893-28.691-78.804c2.73-32.515-21.376-61.273-53.909-64.253    c-8.507-31.313-40.835-50.269-72.65-41.951c-18.764-26.57-55.648-33.238-82.599-14.551c-27.074-18.803-65.91-11.914-84.6,14.551    c-31.874-8.325-64.153,10.674-72.65,41.951c-32.482,2.975-56.642,31.705-53.909,64.253    c-29.543,13.912-42.365,49.151-28.691,78.804c-21.96,22.138-23.867,59.111-0.515,83.155c4.939-33.58,28.641-61.739,61.224-72.106    c7.839-15.02,19.694-27.343,34.625-35.944c14.566-8.426,31.328-12.646,48.208-11.913c12.484-11.415,27.826-18.946,44.809-21.936    c17.056-3.033,35.951-1.025,51.498,5.319c15.547-6.345,32.456-8.294,49.484-5.334c16.983,3.004,32.339,10.535,44.824,21.95    c16.719-0.747,33.629,3.487,48.223,11.913c14.917,8.602,26.771,20.94,34.611,35.944c16.104,5.129,30.244,14.609,41.234,27.68    c10.638,12.682,17.33,27.63,19.829,43.637c1.578-1.634,3.616-2.709,4.993-4.557C517.549,275.345,515.685,241.555,494.961,220.665z     M386.161,169.557c-6.602,4.952-16.004,3.655-21.013-2.989c-53.939-71.919-164.176-71.865-218.075,0    c-5.012,6.638-14.39,7.942-21.013,2.989c-6.622-4.983-7.956-14.39-2.989-21.013c65.97-87.681,199.852-88.022,266.078,0    C394.118,155.167,392.784,164.574,386.161,169.557z" data-original="#000000"  data-old_color="#000000" fill="#0D1137"/>
	</g>
</g></g> </svg>  );
};
