import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { closeModal } from "@redq/reuse-modal";
import { Input } from "../../components/forms/input";
import {
	Button,
	IconWrapper,
	Wrapper,
	Container,
	LogoWrapper,
	Heading,
	SubHeading,
	HelperText,
	Offer,
	// Input,
	Divider,
	LinkButton,
} from "./authentication-form.style";
import { Facebook } from "../../assets/icons/Facebook";
import { Google } from "../../assets/icons/Google";
import { AuthContext } from "../../contexts/auth/auth.context";
import { FormattedMessage, useIntl } from "react-intl";
import { auth } from "../../firebase";
import { FIREBASE_ERRORS } from "../../constants/firebaseMessages";
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';

export default function SignOutModal() {
	const intl = useIntl();
	const { authDispatch } = useContext(AuthContext);
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");
	const [errorMsg, setError] = useState("");

	const toggleSignInForm = () => {
		authDispatch({
			type: "SIGNIN",
		});
	};

	const handleSignup = async () => {
		try {
			if (password?.trim() && name?.trim() && email?.trim()) {
				setLoading(true);
				await auth.createUserWithEmailAndPassword(email, password);
				const user = await auth.currentUser;
				let uid = "";

				if (user != null) {
					uid = user.uid;

					const data = {
						email: email,
						password: password,
						name: name,
						id: uid,
					};

					try {
						await axios.post(
							"https://nora-rey-backend.herokuapp.com/api/mailer/register",
							data,
							{
								headers: {
									accept: "application/json",
									"Accept-Language": "en-US,en;q=0.8",
								},
							}
						);
						setLoading(false);
					
						localStorage.setItem('access_token', `${email}.${password}`);
						ReactPixel.track('CompleteRegistration');
						authDispatch({
							type: "SIGNIN_SUCCESS",
						});
						closeModal();
					} catch (e) {
						user
							.delete()
							.then(function () {
								setLoading(false);
								setError("Ha ocurrido un error.");
							})
							.catch(function (error) {
								setLoading(false);
								setError("Ha ocurrido un error.");
							});
					}
				}
			} else {
				setError("Debes llenar todos los campos.");
			}
		} catch (error) {
			setLoading(false);
			var errorCode = error.code;
			var errorMessage = error.message;
			const customError = FIREBASE_ERRORS[errorCode];
			if (customError) {
				setError(customError);
			} else {
				setError("Ha ocurrido un error.");
			}
		}
	};

	return (
		<Wrapper>
			<Container>
				<Heading>
					<FormattedMessage id="signUpBtnText" defaultMessage="Sign Up" />
				</Heading>
				<SubHeading>
					<FormattedMessage
						id="signUpText"
						defaultMessage="Every fill is required in sign up"
					/>
				</SubHeading>
				<Input
					type="text"
					placeholder={"Nombre y apellido"}
					onChange={(e) => setName(e.target.value)}
					height="48px"
					backgroundColor="#F7F7F7"
					mb="10px"
				/>
				<Input
					type="text"
					placeholder={intl.formatMessage({
						id: "emailAddressPlaceholder",
						defaultMessage: "Email Address or Contact No.",
					})}
					onChange={(e) => setEmail(e.target.value)}
					height="48px"
					backgroundColor="#F7F7F7"
					mb="10px"
				/>
				<Input
					type="password"
					placeholder={intl.formatMessage({
						id: "passwordPlaceholderRegister",
						defaultMessage: "Password (min 6 characters)",
					})}
					onChange={(e) => setPassword(e.target.value)}
					height="48px"
					backgroundColor="#F7F7F7"
					mb="10px"
				/>
				<Button
					variant="primary"
					size="big"
					width="100%"
					type="submit"
					onClick={handleSignup}
				>
					<FormattedMessage id={loading ? "continueBtnLoading" : "continueBtn"} defaultMessage="Continue" />
				</Button>
				<p>{`${errorMsg}`}</p>

				<Divider></Divider>
				<Offer style={{ padding: "20px 0" }}>
					<FormattedMessage
						id="alreadyHaveAccount"
						defaultMessage="Already have an account?"
					/>{" "}
					<LinkButton onClick={toggleSignInForm}>
						<FormattedMessage id="loginBtnText" defaultMessage="Login" />
					</LinkButton>
				</Offer>
			</Container>
		</Wrapper>
	);
}
