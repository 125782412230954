import React, { useContext } from 'react';
import { ProfileContext } from '../../../contexts/profile/profile.context';
import {
  SettingsForm,
  SettingsFormContent,
  HeadingSection,
  Title,
  Row,
  Col,
} from './settings.style';
import { Button } from '../../../components/button/button';
import { Input } from '../../../components/forms/input';
import { FormattedMessage } from 'react-intl';
import { Label } from '../../../components/forms/label';
import Contact from '../../../features/contact/contact';
import Address from '../../../features/address/address';
import Payment from '../../../features/payment/payment';

const SettingsContent = ({ deviceType }) => {
  const { state, dispatch } = useContext(ProfileContext);
  console.log(state)

  const handleChange = (e) => {
    const { value, name } = e.target;
    dispatch({
      type: 'HANDLE_ON_INPUT_CHANGE',
      payload: { value, field: name },
    });
  };

  return (
    <SettingsForm>
      <SettingsFormContent>
        <HeadingSection>
          <Title>
            <FormattedMessage
              id='profilePageTitle'
              defaultMessage='Your Profile'
            />
          </Title>
        </HeadingSection>
        <Row style={{ alignItems: 'flex-end', marginBottom: '50px' }}>
          <Col xs={12} sm={5} md={5} lg={5}>
            <Label>
              <FormattedMessage
                id='profileNameField'
                defaultMessage='Your Name'
              />
            </Label>
            <Input
              type='text'
              label='Name'
              disabled={true}
              name='name'
              value={state.name}
              style={{border: 'none'}}
              onChange={handleChange}
              backgroundColor='#FFFFFF'
              height='48px'
            />
          </Col>

          <Col xs={12} sm={5} md={5} lg={5}>
            <Label>
              <FormattedMessage
                id='profileEmailField'
                defaultMessage='Your Email'
              />
            </Label>
            <Input
              type='email'
              name='email'
              label='Email Address'
              disabled={true}
              value={state.email}
              style={{border: 'none'}}
              onChange={handleChange}
              backgroundColor='#FFFFFF'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <SettingsFormContent>
              <Contact />
            </SettingsFormContent>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} style={{ position: 'relative' }}>
            <SettingsFormContent>
              <Address />
            </SettingsFormContent>
          </Col>
        </Row>
      </SettingsFormContent>
    </SettingsForm>
  );
};

export default SettingsContent;
