import React from "react";

import AppLayout from "../layouts/app-layout";

const withNavbar = (Component) => (props) => (

	<AppLayout>
		<Component {...props} />
	</AppLayout>
);

export { withNavbar };
