import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../../../components/button/button";
import { toast, ToastContainer } from "react-toastify";
import { CURRENCY } from "../../../utils/constant";
import { Scrollbars } from "react-custom-scrollbars";
import { v4 as uuidv4 } from "uuid";
import { generateUniqueId } from "../../../utils/id";
import "../../../../node_modules/react-toastify/dist/ReactToastify.css";
import {
	SpinnerContainer,
	Spinner,
} from "../../user-profile/order/order.style";
import { CardLogo } from "../../../components/payment-card/payment-card.style";
import visa from "../../../assets/images/visaCard.jpg";
import CheckoutWrapper, {
	CheckoutContainer,
	CheckoutInformation,
	InformationBox,
	DeliverySchedule,
	CheckoutSubmit,
	HaveCoupon,
	CouponBoxWrapper,
	CouponInputBox,
	CouponCode,
	RemoveCoupon,
	TermConditionText,
	TermConditionLink,
	CartWrapper,
	CalculationWrapper,
	OrderInfo,
	Title,
	ItemsWrapper,
	Items,
	Quantity,
	Multiplier,
	ItemInfo,
	Price,
	TextWrapper,
	Text,
	Bold,
	Small,
	NoProductMsg,
	NoProductImg,
} from "./checkout-two.style";

import {
	getAddress,
	getCity,
	getAddressDetail,
	getTentativeDelivery,
	getTentativePickup,
	getWarning,
	getDeliveryMethod,
} from "../../../utils/cartHelper";

import { NoCartBag } from "../../../assets/icons/NoCartBag";
import ReactPixel from 'react-facebook-pixel';

import Sticky from "react-stickynode";
import { ProfileContext } from "../../../contexts/profile/profile.context";
import { FormattedMessage } from "react-intl";
import { useCart } from "../../../contexts/cart/use-cart";
import { useLocale } from "../../../contexts/language/language.provider";
import { useWindowSize } from "../../../utils/useWindowSize";
import Coupon from "../../../features/coupon/coupon";
import Schedules from "../../../features/schedule/schedule";
import Contact from "../../../features/contact/contact";
import Payment from "../../../features/payment/payment";
import Address from "../../../features/address/address";
import { ORDER_STATUS } from "../../../constants/orderStatus";
import axios from "axios";
import SiemprePago from "../../../scripts/SiemprePago";
import {
	SIEMPRE_PAGO_CODES,
	SIEMPRE_PAGO_TRANSACTION_CODES,
} from "../../../constants/siempre_pago_codes";

import { css } from "glamor";

const containerStyle = css({
	position: "relative",
});

const PICKUP = 1;
const PICKUP_POCITOS = 3;
const DELIVERY = 2;

const OrderItem = ({ product }) => {
	const { id, quantity, title, name, unit, price, salePrice } = product;
	const displayPrice = salePrice ? salePrice : price;
	return (
		<Items key={id}>
			<Quantity>{quantity}</Quantity>
			<Multiplier>x</Multiplier>
			<ItemInfo>
				{name ? name : title} {unit ? `| ${unit}` : ""}
			</ItemInfo>
			<Price>
				{CURRENCY}
				{displayPrice * quantity}
			</Price>
		</Items>
	);
};

const CheckoutWithSidebar = ({ token, deviceType }) => {
	let history = useHistory();
	const [hasCoupon, setHasCoupon] = useState(false);
	const { state } = useContext(ProfileContext);
	const { isRtl } = useLocale();
	const {
		items,
		removeCoupon,
		coupon,
		clearCart,
		delivery,
		cartItemsCount,
		calculatePrice,
		calculateDiscount,
		calculateSubTotalPrice,
		addTemporalOrder,
		shouldPayDelivery,
		handleDelivery,
	} = useCart();
	const [loading, setLoading] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const { address, contact, id, schedules, name, email } = state;

	const size = useWindowSize();

	const addDeliveryCost = shouldPayDelivery();

	const tentativePickup = getTentativePickup(items);
	const tentativeDelivery = getTentativeDelivery(items);

	const [message, setMessage] = useState('');

	const { limit, showWarning } = getWarning(items, delivery);

	const warningMessage = `Determinados productos llevan un tiempo de producción mayor al previsto (${limit}hrs), su fecha de entrega se ha ajustado.`;

	const newSchedule = [
		{
			id: "1",
			type: "primary",
			title: "Pick up en Carrasco",
			subtitle: "Schroeder 6464",
			time_slot:
				items && items.length
					? `${tentativePickup}: 10.00 AM - 8.00 PM`
					: "No hay información",
			disabled: false,
		},
		{
			id: "3",
			type: "secondary",
			title: "Pick up en Pocitos",
			subtitle: "26 de Marzo 1153",
			time_slot:
				items && items.length
					? `${tentativePickup}: 10.00 AM - 8.00 PM`
					: "No hay información",
			disabled: false,
		},
		{
			id: "2",
			type: "secondary",
			title: "Delivery",
			time_slot:
				items && items.length
					? `${tentativeDelivery}: 10.00 AM - 3.00 PM`
					: "No hay información",
			disabled: false,
		},
	];

	const finalDeliveryMethod = delivery ? DELIVERY : getDeliveryMethod(schedules);

	const handleSubmit = async () => {
		if (isValid) {
			ReactPixel.track('InitiateCheckout');
			SiemprePago(() => {
				// eslint-disable-next-line no-undef
				PWCheckout.SetProperties({
					name: "Nora Rey - SiemprePago",
					email: email || "",
					button_label: "Pagar #monto#",
					description: "",
					checkout_card: "1",
					currency: "UYU",
					amount: calculatePrice(),
					lang: "ESP",
					form_id: "commerce_form",
					autoSubmit: "false",
					close_onclick: "false",
				});

				// eslint-disable-next-line no-undef
				PWCheckout.Bind("tokenCreated", onTokenCreated);

				// eslint-disable-next-line no-undef
				openIframe(PWCheckout);

				var formGroup = document.getElementsByClassName("form-group");

				for (var i = 0, len = formGroup.length; i < len; i++) {
					formGroup[i].style.display = "flex";
					formGroup[i].style["flex-direction"] = "column";
					formGroup[i].style["align-items"] = "stretch";
				}
			});
		}
	};

	const onTokenCreated = async (tokenString) => {
		const token = JSON.stringify(tokenString);
		const parsedToken = JSON.parse(token);
		const cardToken = parsedToken?.TokenId;
		const paymentType = parsedToken?.CardType;

		if (!cardToken) return;

		const fakeID = generateUniqueId();

		let order = {
			userId: id,
			status: delivery
				? ORDER_STATUS.DELIVERY.confirmed
				: ORDER_STATUS.PICKUP.confirmed,
			date: new Date(),
			amount: calculatePrice(),
			discount: calculateDiscount(),
			products: items || [],
			subtotal: calculateSubTotalPrice(),
			deliveryTime: delivery
				? tentativeDelivery
				: tentativePickup || "No hay información",
			coupon: coupon
				? {
					id: coupon.id,
					code: coupon.code,
					discountInPercent: coupon.discountInPercent,
				}
				: null,
			deliveryFee: delivery ? 260 : 0,
			deliveryMethod: finalDeliveryMethod,
			shouldPayDelivery: addDeliveryCost,
			deliveryAddress:
				address?.length > 0 ? getAddress(...address) : "No hay información.",
		};

		setLoading(true);

		try {
			const body = {
				token: cardToken,
				orderId: fakeID,
				amount: order.amount,
				email: email || "",
				stateData: order,
				customerAddress:
					address?.length > 0 ? getAddressDetail(...address) : "",
				customerCity: address?.length > 0 ? getCity(...address) : "",
				customerName: name || "",
				customerPhone: contact && contact.length > 0 ? contact[0].number : "-",
			};

			const res = await axios.post(
				`https://nora-rey-backend.herokuapp.com/api/orders/siempre-pago-payment`,
				body
			);

			if (res) {
				const { data } = res;
				if (data) {
					const {
						errors,
						orderId,
						purchaseId,
						transactionErrorCode,
						transactionId,
						transactionStatus,
						transactionStatusId,
					} = data;
					if (errors && errors.length > 0) {
						const firstError = errors[0];
						const translatedError = SIEMPRE_PAGO_CODES.find(
							(er) => er.id == firstError.ErrorCode
						);
						const finalMessage =
							translatedError && translatedError.message
								? translatedError.message
								: "Ha ocurrido un error procesando el pago.";
						toast.error(finalMessage, {
							position: "bottom-left",
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
					} else {
						if (
							transactionStatusId == SIEMPRE_PAGO_TRANSACTION_CODES.APPROVED
						) {
							order.id = orderId;
							order.purchaseId = purchaseId;
							order.transactionId = transactionId;
							try {
								const copiedOrder = { ...order };
								addTemporalOrder(copiedOrder);
								toast.success("Tu pago se ha completado con éxito.", {
									position: "bottom-left",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
								history.push("/order-received");
							} catch (error) {
								console.log("error");
								toast.error("Ha ocurrido un error.", {
									position: "bottom-left",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}
						} else if (
							transactionStatusId == SIEMPRE_PAGO_TRANSACTION_CODES.PENDING
						) {
							//PENDING, have to check for commerce action.
							toast.warn(
								"Estamos procesando tu pago, cuando esté aprovado recibirás la confirmación.",
								{
									position: "bottom-left",
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								}
							);
						} else if (
							transactionStatusId == SIEMPRE_PAGO_TRANSACTION_CODES.REJECTED
						) {
							//REJECTED
							if (transactionErrorCode) {
								const translatedError = SIEMPRE_PAGO_CODES.find(
									(er) => er.id == transactionErrorCode
								);
								const finalMessage =
									translatedError && translatedError.message
										? translatedError.message
										: "Ha ocurrido un error procesando el pago.";
								toast.error(finalMessage, {
									position: "bottom-left",
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							} else {
								toast.error("Ha ocurrido un error procesando el pago.", {
									position: "bottom-left",
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}
						} else if (
							transactionStatusId == SIEMPRE_PAGO_TRANSACTION_CODES.CANCELLED
						) {
							//CANCELLED
							if (transactionErrorCode) {
								const translatedError = SIEMPRE_PAGO_CODES.find(
									(er) => er.id == transactionErrorCode
								);
								const finalMessage =
									translatedError && translatedError.message
										? translatedError.message
										: "Ha ocurrido un error procesando el pago.";
								toast.error(finalMessage, {
									position: "bottom-left",
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							} else {
								toast.error("Ha ocurrido un error procesando el pago.", {
									position: "bottom-left",
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}
						}
					}
				}
			}
		} catch (e) {
			const { data } = e;
			if (data) {
				const { errors } = data;
				const { message } = data;

				if (errors && errors.length > 0) {
					const firstError = errors[0];
					const translatedError = SIEMPRE_PAGO_CODES.find(
						(er) => er.id == firstError.ErrorCode
					);
					const finalMessage =
						translatedError && translatedError.message
							? translatedError.message
							: "Ha ocurrido un error procesando el pago.";
					toast.error(finalMessage, {
						position: "bottom-left",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
				} else if (message) {
					toast.error(message, {
						position: "bottom-left",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
				}
			} else {
				console.log(e);
				toast.error("Ha ocurrido un error inesperado.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		}

		setLoading(false);
	};

	const openIframe = (pWCheckout) => {
		pWCheckout.SetStyle({
			buttonColor: "99BB51",
			backgroundColor: "f5f2e8",
			buttonHoverColor: "CBDF70",
			buttonTextColor: "ffffff",
			buttonTextHoverColor: "ffffff",
			inputBackgroundColor: "f5f4f0",
			inputTextColor: "767676",
			inputErrorColor: "ff0000",
			inputAddonBackgroundColor: "99BB51",
			labelColor: "CBDF70",
		});

		// <!-- Evento que se dispara al generarse el token -->
		pWCheckout.Bind("tokenCreated", onTokenCreated);

		pWCheckout.Iframe.OpenIframeWithPaymentMediaOptions([1, 6]);
	};

	useEffect(() => {
		if (
			calculatePrice() > 0 &&
			cartItemsCount > 0 &&
			address.length > 0 &&
			contact.length &&
			schedules.length
		) {
			setIsValid(true);
		} else {
			setIsValid(false);
		}
	}, [state]);

	useEffect(() => {
		handleDelivery(false);
		return () => {
			handleDelivery(false);
		};
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`https://nora-rey-backend.herokuapp.com/api/holidays/`
				);
				if (response.data && response.data.holidaysArray && response.data.holidaysArray.length) {
					const { holidaysArray } = response.data;
					const currentDate = holidaysArray[0];
					const start = new Date(currentDate.start);
					const end = new Date(currentDate.end);
					const message = currentDate.message;
					const today = new Date();
					var diffDaysStart = parseInt((start - today) / (1000 * 60 * 60 * 24), 10);

					if (diffDaysStart < 2 || (today >= start && today < end)) {
						//Should block dates
						setMessage(message)
					}
				}
			} catch (error) {
				// setError(error);
			}
		};

		fetchData();
	}, []);

	const closedDatesMessage = (
		<div>
			<h5>Atención</h5>
			<p>{message}</p>
			<p style={{ fontStyle: 'italic', marginTop: 5 }}>El equipo de Nora Rey.</p>
		</div>
	)

	const shouldClose = !!message;

	return (
		<form>
			<ToastContainer className={containerStyle} />

			<form id="commerce_form">
				<input id="PWToken" name="PWToken" type="hidden" />
			</form>
			<CheckoutWrapper>
				<CheckoutContainer>
					{loading ? (
						<SpinnerContainer>
							<Spinner></Spinner>
						</SpinnerContainer>
					) : (
						<CheckoutInformation>
							{/* DeliverySchedule */}
							<InformationBox>
								<DeliverySchedule>
									{shouldClose ? closedDatesMessage : (
										<Schedules
											increment={true}
											setDelivery={(hasDelivery) => handleDelivery(hasDelivery)}
											newSchedule={newSchedule}
											warningMessage={showWarning ? warningMessage : ""}
										/>
									)}

								</DeliverySchedule>
							</InformationBox>

							{/* DeliveryAddress */}
							{
								<InformationBox>
									<Address
										increment={true}
										flexStart={true}
										isDelivery={delivery}
										buttonProps={{
											variant: "text",
											type: "button",
											className: "addButton",
										}}
										icon={true}
									/>
								</InformationBox>
							}

							{/* Contact number */}
							<InformationBox>
								<Contact
									increment={true}
									flexStart={true}
									buttonProps={{
										variant: "text",
										type: "button",
										className: "addButton",
									}}
									icon={true}
								/>
							</InformationBox>
							{/* PaymentOption */}

							<InformationBox
								className="paymentBox"
								style={{ paddingBottom: 30 }}
							>
								<Payment deviceType={deviceType} increment={true} />

								{/* Coupon start */}
								{coupon ? (
									<CouponBoxWrapper>
										<CouponCode>
											<FormattedMessage id="couponApplied" />
											<span>{coupon.code}</span>

											<RemoveCoupon
												onClick={(e) => {
													e.preventDefault();
													removeCoupon();
													setHasCoupon(false);
												}}
											>
												<FormattedMessage id="removeCoupon" />
											</RemoveCoupon>
										</CouponCode>
									</CouponBoxWrapper>
								) : (
									<CouponBoxWrapper>
										{!hasCoupon ? (
											<HaveCoupon onClick={() => setHasCoupon((prev) => !prev)}>
												<FormattedMessage
													id="specialCode"
													defaultMessage="Have a special code?"
												/>
											</HaveCoupon>
										) : (
											<>
												<CouponInputBox>
													<Coupon
														errorMsgFixed={true}
														className="normalCoupon"
													/>
												</CouponInputBox>
											</>
										)}
									</CouponBoxWrapper>
								)}

								<TermConditionText>
									<FormattedMessage
										id="termAndConditionHelper"
										defaultMessage="By making this purchase you agree to our"
									/>
									<Link href="#">
										<TermConditionLink>
											<FormattedMessage
												id="termAndCondition"
												defaultMessage="terms and conditions."
											/>
										</TermConditionLink>
									</Link>
								</TermConditionText>

								{/* CheckoutSubmit */}
								<CheckoutSubmit>
									<Button
										type="button"
										onClick={handleSubmit}
										disabled={!isValid || shouldClose}
										size="big"
										loading={loading}
										style={{ width: "100%" }}
									>
										<FormattedMessage
											id="processCheckout"
											defaultMessage="Proceed to Checkout"
										/>
									</Button>
								</CheckoutSubmit>

								<TextWrapper>
									<Text>
										<Small>
											<FormattedMessage
												id="emailConfirmationText"
												defaultMessage="Te llegará la confirmación por correo electrónico."
											/>{" "}
										</Small>
									</Text>
								</TextWrapper>
							</InformationBox>
						</CheckoutInformation>
					)}

					<CartWrapper>
						<Sticky
							enabled={size.width >= 768 ? true : false}
							top={120}
							innerZ={999}
						>
							<OrderInfo>
								<Title>
									<FormattedMessage
										id="cartTitle"
										defaultMessage="Your Order"
									/>
								</Title>

								<Scrollbars
									universal
									autoHide
									autoHeight
									autoHeightMax="390px"
									renderView={(props) => (
										<div
											{...props}
											style={{
												...props.style,
												marginLeft: isRtl ? props.style.marginRight : 0,
												marginRight: isRtl ? 0 : props.style.marginRight,
												paddingLeft: isRtl ? 15 : 0,
												paddingRight: isRtl ? 0 : 15,
											}}
										/>
									)}
								>
									<ItemsWrapper>
										{cartItemsCount > 0 ? (
											items.map((item) => (
												<OrderItem key={`cartItem-${item.id}`} product={item} />
											))
										) : (
											<>
												<NoProductImg>
													<NoCartBag />
												</NoProductImg>

												<NoProductMsg>
													<FormattedMessage
														id="noProductFound"
														defaultMessage="No products found"
													/>
												</NoProductMsg>
											</>
										)}
									</ItemsWrapper>
								</Scrollbars>

								<CalculationWrapper>
									<TextWrapper>
										<Text>
											<FormattedMessage
												id="subTotal"
												defaultMessage="Subtotal"
											/>
										</Text>
										<Text>
											{CURRENCY}
											{calculateSubTotalPrice()}
										</Text>
									</TextWrapper>

									<TextWrapper>
										<Text>
											<FormattedMessage
												id="intlOrderDetailsDelivery"
												defaultMessage="Delivery Fee"
											/>
										</Text>
										<Text>
											{CURRENCY}
											{delivery == true && addDeliveryCost ? "260" : "0"}
										</Text>
									</TextWrapper>

									<TextWrapper>
										<Text>
											<FormattedMessage
												id="discountText"
												defaultMessage="Discount"
											/>
										</Text>
										<Text>
											{CURRENCY}
											{calculateDiscount()}
										</Text>
									</TextWrapper>

									<TextWrapper style={{ marginTop: 20 }}>
										<Bold>
											<FormattedMessage id="totalText" defaultMessage="Total" />{" "}
											<Small>
												(
												<FormattedMessage
													id="vatText"
													defaultMessage="Incl. IVA"
												/>
												)
											</Small>
										</Bold>
										<Bold>
											{CURRENCY}
											{calculatePrice()}
										</Bold>
									</TextWrapper>

									<TextWrapper>
										<Text>
											<Bold>
												<Small>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															color: "#77798C",
														}}
													>
														<FormattedMessage
															id="visaText"
															defaultMessage="Trabajamos con tarjetas VISA."
														/>{" "}
														<CardLogo>
															<img src={visa} style={{ marginLeft: "5px" }} />
														</CardLogo>
													</div>
												</Small>
											</Bold>
										</Text>
									</TextWrapper>
								</CalculationWrapper>
							</OrderInfo>
						</Sticky>
					</CartWrapper>
				</CheckoutContainer>
			</CheckoutWrapper>
		</form>
	);
};

export default CheckoutWithSidebar;
