import React, { useEffect, useContext, useState } from "react";
import { Modal } from "@redq/reuse-modal";
import { ToastContainer } from "react-toastify";
import {  useHistory } from "react-router-dom";
import { ProfileProvider } from "../contexts/profile/profile.provider";
import SettingsContent from "../features/user-profile/settings/settings";
import "../../node_modules/react-toastify/dist/ReactToastify.css";
import {
	PageWrapper,
	SidebarSection,
	ContentBox,
} from "../features/user-profile/user-profile.style";
import Sidebar from "../features/user-profile/sidebar/sidebar";
import Footer from "../layouts/footer";
import ErrorMessage from "../components/error-message/error-message";
import useUser from "../data/use-user";
import { withNavbar } from "../hocs/withNavbar";
import { auth } from "../firebase";
import { AuthContext } from "../contexts/auth/auth.context";
import axios from "axios";
import {
	SpinnerContainer,
	Spinner,
} from "../features/user-profile/order/order.style";

import firebase from "firebase/app";
import "firebase/auth";
import { css } from "glamor";

const containerStyle = css({
	position: "relative",
});

const ProfilePage = ({ deviceType }) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	const [userError, setError] = useState(null);
	let history = useHistory();


	useEffect(() => {
		const fetchData = async () => {
			try {
				firebase.auth().onAuthStateChanged(async function (user) {
					if (user) {
						const result = await axios(
							`https://nora-rey-backend.herokuapp.com/api/users/${user.uid}`
						);
						const { data } = result;
						setData(data?.user);
					} else {
						// No user is signed in.
						history.push('/');
					}
				});
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	if (userError) return <ErrorMessage message={userError.message} />;
	if (loading)
		return (
			<SpinnerContainer>
				<Spinner></Spinner>
			</SpinnerContainer>
		);

	return (
		<>
			{data && (
				<ProfileProvider initData={data}>
					<ToastContainer className={containerStyle} />
					<Modal>
						<PageWrapper>
							<SidebarSection>
								<Sidebar />
							</SidebarSection>
							<ContentBox>
								<SettingsContent deviceType={deviceType} />
							</ContentBox>

							<Footer />
						</PageWrapper>
					</Modal>
				</ProfileProvider>
			)}
		</>
	);
};

export default withNavbar(ProfilePage);
