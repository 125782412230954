import React from 'react';
import { FormattedMessage } from 'react-intl';
import NavLink from './../../../components/nav-link/nav-link';
import { AUTHORIZED_MENU_ITEMS, TERMS_AND_SERVICES_PAGE } from '../../../site-settings/site-navigation';

// type Props = {
//   onLogout: () => void;
// };

export const AuthorizedMenu = ({ onLogout }) => {
  return (
    <>
      {AUTHORIZED_MENU_ITEMS.map((item, idx) => (
        <NavLink
          key={idx}
          className='menu-item'
          href={item.href}
          label={item.defaultMessage}
          intlId={item.id}
        />
      ))}
        <NavLink
          key={'nav.terms_and_services'}
          className='menu-item'
          href={TERMS_AND_SERVICES_PAGE}
          label='Términos y condiciones'
          intlId={'navlinkTerms'}
        />
      <div className='menu-item' onClick={onLogout}>
        <a>
          <span>
            <FormattedMessage id='nav.logout' defaultMessage='Logout' />
          </span>
        </a>
      </div>
    </>
  );
};
