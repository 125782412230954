import React from 'react';
export const Tea = ({
  color = '#0D1137',
  width = '30px',
  height = '30px',
}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 480 480"  space="preserve" width={width} height={height}><g><g>
	<g>
		<g>
			<path d="M440,216h-64v-16c0-13.255-10.745-24-24-24H80c-13.255,0-24,10.745-24,24v112c0.048,50.25,27.781,96.384,72.136,120H8     c-4.418,0-8,3.582-8,8c0.026,22.08,17.92,39.974,40,40h352c22.08-0.026,39.974-17.92,40-40c0-4.418-3.582-8-8-8H303.792     c39.303-20.933,65.869-59.781,71.12-104H440c22.08-0.026,39.974-17.92,40-40v-32C479.974,233.92,462.08,216.026,440,216z      M414.632,448c-3.403,9.582-12.464,15.988-22.632,16H40c-10.168-0.012-19.229-6.418-22.632-16H414.632z M359.776,319.504     C355.729,382.705,303.33,431.919,240,432h-48c-66.243-0.075-119.925-53.757-120-120V200c0-4.418,3.582-8,8-8h32v20.688     l-21.656,21.656c-1.5,1.5-2.344,3.534-2.344,5.656v64c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-64     c0-2.122-0.844-4.156-2.344-5.656L128,212.688V192h224c4.418,0,8,3.582,8,8C360,200,359.928,317.024,359.776,319.504z      M120,227.312l16,16V296h-32v-52.688L120,227.312z M464,288c0,13.255-10.745,24-24,24h-64v-80h64c13.255,0,24,10.745,24,24V288z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
			<path d="M250.872,127.2C242.472,134.192,232,142.92,232,160h16c0-9.144,4.744-13.544,13.128-20.528     c8.384-6.984,18.872-15.736,18.872-32.816c0-17.08-10.472-25.808-18.872-32.8S248,62.456,248,53.312     c0-9.144,4.744-13.528,13.12-20.504C269.496,25.832,280,17.08,280,0h-16c0,9.136-4.744,13.528-13.12,20.504     C242.504,27.48,232,36.232,232,53.312c0,17.08,10.472,25.8,18.872,32.8S264,97.504,264,106.656     C264,115.808,259.256,120.2,250.872,127.2z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
			<path d="M186.872,127.2C178.472,134.192,168,142.92,168,160h16c0-9.144,4.744-13.544,13.128-20.528     c8.384-6.984,18.872-15.736,18.872-32.816c0-17.08-10.472-25.808-18.872-32.8c-8.4-6.992-13.128-11.4-13.128-20.544     c0-9.144,4.744-13.528,13.12-20.504S216,17.08,216,0h-16c0,9.136-4.744,13.528-13.12,20.504C178.504,27.48,168,36.232,168,53.312     c0,17.08,10.472,25.8,18.872,32.8c8.4,7,13.128,11.392,13.128,20.544C200,115.808,195.256,120.2,186.872,127.2z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
			<rect x="112" y="248" width="16" height="16" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
		</g>
	</g>
</g></g> </svg>
 );
};
