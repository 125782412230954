import React, { useContext } from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { closeModal } from "@redq/reuse-modal";
import { FormikProps, ErrorMessage, Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import MaskedInput from "react-text-mask";
import { ProfileContext } from "../../contexts/profile/profile.context";
import { Button } from "../button/button";
// import { UPDATE_CONTACT } from 'graphql/mutation/contact';
import { FieldWrapper, Heading } from "./contact-card.style";
import useUser from "../../data/use-user";
import { FormattedMessage } from "react-intl";

const ContactValidationSchema = Yup.object().shape({
	number: Yup.string().required("Este campo es requerido."),
});

const CreateOrUpdateContact = ({ item }) => {
	const initialValues = {
		id: item.id || null,
		type: item.type || "secondary",
		number: item.number || "",
	};
	const { state, dispatch } = useContext(ProfileContext);

	const { addOrUpdateContactNumber } = useUser();

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			await addOrUpdateContactNumber(state?.id, values);
			dispatch({ type: "ADD_OR_UPDATE_CONTACT", payload: values });
			closeModal();
			toast.success("Se han modificado sus datos exitosamente", {
				position: "bottom-left",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		} catch (e) {
			toast.error("Ha ocurrido un error, inténtelo nuevamente", {
				position: "bottom-left",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
		setSubmitting(false);
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={ContactValidationSchema}
		>
			{({ values, handleChange, handleBlur, isSubmitting }) => (
				<Form>
					<Heading>{"Agregar nuevo celular"}</Heading>
					<FieldWrapper>
						<MaskedInput
							mask={[
								/[0-9]/,
								/\d/,
								/\d/,
								" ",
								/\d/,
								/\d/,
								/\d/,
								" ",
								/\d/,
								/\d/,
								/\d/,
							]}
							className="form-control"
							placeholder="Ingrese un celular"
							guide={false}
							id="my-input-id"
							value={values.number}
							onChange={handleChange}
							onBlur={handleBlur}
							name="number"
							render={(ref, props) => <StyledInput ref={ref} {...props} />}
						/>
					</FieldWrapper>
					<ErrorMessage name="number" component={StyledError} />

					<Button
						disabled={isSubmitting}
						type="submit"
						style={{ width: "100%", height: "44px" }}
					>
						<FormattedMessage
							id="savedContactId"
							defaultMessage="Save Contact"
						/>
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default CreateOrUpdateContact;

const StyledInput = styled.input`
	width: 100%;
	height: 54px;
	border-radius: ${themeGet("radii.base", "6px")};
	font-family: ${themeGet("fonts.body", "Lato, sans-serif")};
	border: 1px solid ${themeGet("colors.gray.700", "#e6e6e6")};
	color: ${themeGet("colors.text.bold", "#0D1136")};
	font-size: 16px;
	line-height: 19px;
	font-weight: ${themeGet("fontWeights.regular", "400")};
	padding: 0 18px;
	box-sizing: border-box;
	transition: border-color 0.25s ease;

	&:hover,
	&:focus {
		outline: 0;
	}

	&:focus {
		border-color: ${themeGet("colors.primary.regular", "#9abb51")};
	}

	&::placeholder {
		color: ${themeGet("colors.text.regular", "#77798C")};
	}
`;

const StyledError = styled.div`
	color: red;
	padding-bottom: 10px;
	margin-top: -5px;
`;
