import React from 'react';
import NavLink from '../../../../components/nav-link/nav-link';
import { OFFER_MENU_ITEM, HELP_MENU_ITEM } from '../../../../site-settings/site-navigation';
import LanguageSwitcher from '../language-switcher/language-switcher';
import { HelpIcon } from '../../../../assets/icons/HelpIcon';
import { RightMenuBox } from './right-menu.style';
import AuthMenu from '../auth-menu';

// type Props = {
//   onLogout: () => void;
//   onJoin: () => void;
//   avatar: string;
//   isAuthenticated: boolean;
// };

export const RightMenu = ({
  onLogout,
  avatar,
  isAuthenticated,
  onJoin,
}) => {
  return (
    <RightMenuBox>
      {/* <NavLink
        className="menu-item"
        href={OFFER_MENU_ITEM.href}
        label={OFFER_MENU_ITEM.defaultMessage}
        intlId={OFFER_MENU_ITEM.id}
      /> */}
      {/* <NavLink
        className="menu-item"
        href={HELP_MENU_ITEM.href}
        label={HELP_MENU_ITEM.defaultMessage}
        intlId={HELP_MENU_ITEM.id}
        iconClass="menu-icon"
        icon={<HelpIcon />}
      /> */}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center'}}>
      <div style={{padding: 5, paddingLeft: 10, paddingRight: 10, background: '#eff1af', borderRadius: 10, marginBottom: 10}}>
      <a href="https://www.norarey.com.uy/"><p style={{color: '#0E1138', margin: 0}}>Ir a la página principal</p></a>
        </div>


<AuthMenu
  avatar={avatar}
  onJoin={onJoin}
  onLogout={onLogout}
  isAuthenticated={isAuthenticated}
/>
      </div>
      			
    </RightMenuBox>
  );
};
