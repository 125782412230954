import React from 'react';
// import { useRouter } from 'next/router';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Sticky from 'react-stickynode';
import { Scrollbars } from 'react-custom-scrollbars';
import Popover from '../../components/popover/popover';
import { ArrowDropDown } from '../../assets/icons/ArrowDropDown';
import { CategoryIcon } from '../../assets/icons/CategoryIcon';
import { useLocale } from '../../contexts/language/language.provider';
import { useAppState } from '../../contexts/app/app.provider';
import { useHistory, useLocation } from "react-router-dom";

import {
  SidebarMobileLoader,
  SidebarLoader,
} from '../../components/placeholder/placeholder';
import {
  CategoryWrapper,
  TreeWrapper,
  PopoverHandler,
  PopoverWrapper,
  SidebarWrapper,
  RequestMedicine,
} from './sidebar.style';

import { TreeMenu } from '../../components/tree-menu/tree-menu';
import { REQUEST_MEDICINE_MENU_ITEM } from '../../site-settings/site-navigation';
import useCategory from '../../data/use-category';
import ErrorMessage from '../../components/error-message/error-message';

// type SidebarCategoryProps = {
//   deviceType: {
//     mobile: string;
//     tablet: string;
//     desktop: boolean;
//   };
//   type: string;
// };

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SidebarCategory = (
  {
   deviceType,
  type,
}
) => {
  
  const router = useLocation();
  let query = useQuery();
  let history = useHistory();
  const { categories, categoriesError } = useCategory({ type });
  const { isRtl } = useLocale();
  const isSidebarSticky = useAppState('isSidebarSticky');

  if (categoriesError) {
    return <ErrorMessage message={categoriesError.message} />;
  }
  const { pathname } = router;
  const selectedQueries = query.get('category');


  const onCategoryClick = (slug) => {
    const { type, ...rest } = query;
    if (type) {
      history.push(
        {
          pathname,
          query: { ...rest, category: slug },
        },
        {
          pathname: `/${type}`,
          query: { ...rest, category: slug },
        }
      );
    } else {
      history.push(
        `${pathname}?category=${slug}`
      );
    }
  };


  if (!categories) {
    if (deviceType == "mobile" || deviceType == "tablet") {
      return <SidebarMobileLoader />;
    }
    return <SidebarLoader />;
  }
  return (
    <CategoryWrapper>
      <PopoverWrapper>
        <Popover
          handler={
            <PopoverHandler>
              <div>
                <CategoryIcon />
                Seleccione una categoría
              </div>
              <div>
                <ArrowDropDown />
              </div>
            </PopoverHandler>
          }
          className="category-popover"
          content={
            <>
              <TreeMenu
                data={categories}
                onClick={onCategoryClick}
                active={selectedQueries}
              />
            </>
          }
        />
      </PopoverWrapper>

      <SidebarWrapper style={{ paddingTop: type === 'medicine' ? 0 : 45 }}>
        <Sticky enabled={isSidebarSticky} top={type === 'medicine' ? 89 : 110}>
          <Scrollbars
            universal
            autoHide
            autoHeight
            autoHeightMax={688}
            renderView={(props) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  marginLeft: isRtl ? props.style.marginRight : 0,
                  marginRight: isRtl ? 0 : props.style.marginRight,
                  backgroundColor: 'white'
                }}
              />
            )}
          >
            <TreeWrapper>
              <TreeMenu
                data={categories}
                onClick={onCategoryClick}
                active={selectedQueries}
              />
            </TreeWrapper>
          </Scrollbars>
        </Sticky>
      </SidebarWrapper>
    </CategoryWrapper>
  );
};

export default SidebarCategory;
