import React from "react";
import styled from "styled-components";

const RadioGroupWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: "flex-start";
`;

// type RadioGroupProps = {
//   containerClassName?: string;
//   items: any[];
//   component?: (item: object, index?: number) => React.ReactNode;
//   secondaryComponent?: React.ReactNode;
// };

const RadioGroup = ({
	items = [],
	component,
	containerClassName,
	secondaryComponent,
}) => {
	return (
		<RadioGroupWrapper className={`radioGroup ${containerClassName}`.trim()}>
			{items && items.length
				? items.map((item, index) => component && component(item, index))
				: null}

			{!(items && items.length > 0) && secondaryComponent && secondaryComponent}
		</RadioGroupWrapper>
	);
};

export default RadioGroup;
