import React from "react";
import Table from "rc-table";
import Collapse, { Panel } from "rc-collapse";
import Progress from "../../../../components/progress-box/progress-box";

import {
	OrderListHeader,
	TrackID,
	Status,
	OrderMeta,
	Meta,
	CardWrapper,
	OrderDetail,
	DeliveryInfo,
	DeliveryAddress,
	Address,
	CostCalculation,
	PriceRow,
	Price,
	ProgressWrapper,
	OrderTable,
	OrderTableMobile,
} from "./order-card.style";

import { CURRENCY } from "../../../../utils/constant";
import moment from "moment";

const components = {
	table: OrderTable,
};

const OrderCard = ({ onClick, className, columns, progressData, orders }) => {
	return (
		<>
			<Collapse
				accordion={true}
				className={`accordion ${className}`}
				defaultActiveKey="active"
				expandIcon={() => {}}
			>
				{orders.map((order) => (
					<Panel
						header={
							<CardWrapper onClick={() => onClick(order)}>
								<OrderListHeader>
									<TrackID>
										Id: <span>{order.id}</span>
									</TrackID>
									<Status>{progressData[order.status - 1]}</Status>
								</OrderListHeader>

								<OrderMeta>
									<Meta>
										Fecha del pedido:{" "}
										<span>{moment(order.date).format("DD/MM/YY")}</span>
									</Meta>
									<Meta>
										Método de envío:{" "}
										<span>
											{order.deliveryMethod == 2
												? "Delivery" 
												: order.deliveryMethod == 3 ? "Retiro en local Pocitos" : "Retiro en local Carrasco"  }
										</span>
									</Meta>
									<Meta>
										Fecha de entrega: <span>{order.deliveryTime}</span>
									</Meta>
									<Meta className="price">
										Precio total:
										<span>
											{CURRENCY}
											{order.amount}
										</span>
									</Meta>
								</OrderMeta>
							</CardWrapper>
						}
						headerClass="accordion-title"
						key={order.id}
					>
						<OrderDetail>
							<DeliveryInfo>
								{order.deliveryMethod == 2 ? (
									<DeliveryAddress>
										<h3>Dirección de entrega</h3>
										<Address>{order.deliveryAddress}</Address>
									</DeliveryAddress>
								) : order.deliveryMethod == 3 ? (
									<DeliveryAddress>
										<h3>Retiro en el local Pocitos</h3>
									</DeliveryAddress>
								) : 	<DeliveryAddress>
								<h3>Retiro en el local Carrasco</h3>
							</DeliveryAddress>}

								<CostCalculation>
									<PriceRow>
										Subtotal
										<Price>
											{CURRENCY}
											{order.subtotal}
										</Price>
									</PriceRow>
									<PriceRow>
										Descuento
										<Price>
											{CURRENCY}
											{order.discount}
										</Price>
									</PriceRow>
									<PriceRow>
										Costos de envío
										<Price>
											{CURRENCY}
                      {order.deliveryFee || "0"}
										</Price>
									</PriceRow>
									<PriceRow className="grandTotal">
										Total
										<Price>
											{CURRENCY}
											{order.amount}
										</Price>
									</PriceRow>
								</CostCalculation>
							</DeliveryInfo>

							<ProgressWrapper>
								<Progress data={progressData} status={order.status} />
							</ProgressWrapper>

							<OrderTableMobile>
								<Table
									columns={columns}
									data={order.products}
									rowKey={(record) => record.id}
									components={components}
									scroll={{ x: 450 }}
									// scroll={{ y: 250 }}
								/>
							</OrderTableMobile>
						</OrderDetail>
					</Panel>
				))}
			</Collapse>
		</>
	);
};

export default OrderCard;
