import useSWR from "swr";
import axios from "axios";

const fetcher = (url) => fetch(url).then((res) => res.json());
// const end_point_url = '/'

export default function useUser(id) {

	// if (id) {
	// 	const { data, mutate, error } = useSWR(
	// 		`https://nora-rey-backend.herokuapp.com/api/users/${id}`,
	// 		fetcher
  //   );
  //   realData = data;
  //   realMutate = mutate;
  //   realError = error;
	// }

	const addOrUpdateContactNumber = async (id, contact) => {
		return await axios.post(
			`https://nora-rey-backend.herokuapp.com/api/users/${id}/add-contact`,
			contact
		);
	};
	const addOrUpdateAddress = async (id, address) => {
		return await axios.post(
			`https://nora-rey-backend.herokuapp.com/api/users/${id}/add-address`,
			address
		);
	};

	const addOrUpdatePaymentCard = async (payment_card) => {
		console.log(payment_card, "payment_card");

		// return await fetch(end_point_url,{method: 'POST', body: payment_card });
	};
	const deleteContactNumber = async (id) => {
		return await axios.post(
			`https://nora-rey-backend.herokuapp.com/api/users/${id}/delete-contact`,
			{
				params: {
					id: id
				}
			}
		);
	};
	const deleteAddress = async (id) => {
		return await axios.post(
			`https://nora-rey-backend.herokuapp.com/api/users/${id}/delete-address`,
			{
				params: {
					id: id
				}
			}
		);
	};
	const deletePaymentCard = async (cardId) => {
		console.log(cardId, "cardId");

		// return await fetch(end_point_url,{method: 'POST', body: cardId });
	};

	return {
		// loggedOut,
		addOrUpdateContactNumber,
		addOrUpdateAddress,
		addOrUpdatePaymentCard,
		deleteContactNumber,
		deleteAddress,
		deletePaymentCard,
	};
}
