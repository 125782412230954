import React, { useReducer } from "react";
import { AuthContext } from "./auth.context";
const isBrowser = typeof window !== "undefined";
const INITIAL_STATE = {
	isAuthenticated: !!localStorage.getItem("access_token"),
	currentForm: "signIn",
};

function reducer(state, action) {
  console.log(action)
	switch (action.type) {
		case "SIGNIN":
			return {
				...state,
				currentForm: "signIn",
			};
		case "SIGNIN_SUCCESS":
			return {
				...state,
				isAuthenticated: true,
			};
		case "SIGN_OUT":
			return {
				...state,
				isAuthenticated: false,
			};
		case "SIGNUP":
			return {
				...state,
				currentForm: "signUp",
			};
		case "FORGOTPASS":
			return {
				...state,
				currentForm: "forgotPass",
			};
		case "SET_USER":
			return {
				...state,
				user: action.action.user,
			};
		default:
			return state;
	}
}

export const AuthProvider = ({ children }) => {
	const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);
	return (
		<AuthContext.Provider value={{ authState, authDispatch }}>
			{children}
		</AuthContext.Provider>
	);
};
