import React, { useState, useEffect } from "react";
// import { useRouter } from 'next/router';
import { Modal } from "@redq/reuse-modal";
import ProductSingleWrapper, {
	ProductSingleContainer,
	SpinnerContainer,
	Spinner,
} from "../assets/styles/product-single.style";
import { getAllProducts, getProductBySlug } from "../utils/api/product";

import ProductDetails from "../components/product-details/product-details-one/product-details-one";
import ProductDetailsBook from "../components/product-details/product-details-two/product-details-two";
import CartPopUp from "../features/carts/cart-popup";
import { withNavbar } from "../hocs/withNavbar";
import { useLocation, Redirect } from "react-router-dom";

// type Props = {
//   deviceType?: {
//     mobile: boolean;
//     tablet: boolean;
//     desktop: boolean;
//   };
//   data: any;
//   [key: string]: any;
// };

const getDeviceType = () => {
	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		const deviceType = {
			mobile: false,
			tablet: true,
			desktop: false,
		};
		return deviceType;
	}
	if (
		/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
			ua
		)
	) {
		const deviceType = {
			mobile: true,
			tablet: false,
			desktop: false,
		};
		return deviceType;
	}
	const deviceType = {
		mobile: false,
		tablet: false,
		desktop: true,
	};
	return deviceType;
};

const ProductPage = () => {
	const [data, setData] = useState(null);
	const [relatedCategories, setRelatedCats] = useState(null);
	const [showSantander, setShowSantander] = useState(false);
	const [loading, setLoading] = useState(true);
	let location = useLocation();
	const [currentLocation, setCurrentLocation] = useState("");
	const deviceType = getDeviceType();

	// Handle window event listener
	useEffect(() => {
		const callAPI = async (param) => {
			const { found, santanderActive, relatedCategories } = await getProductBySlug(param);
			setData(found);
			setRelatedCats(relatedCategories)
			setShowSantander(santanderActive);
			setLoading(false);
		};
		const currentSlug = location?.pathname?.split("product/");

		if (currentSlug?.length) {
			callAPI(currentSlug[1]);
		}
	}, [location]);

	if (!loading && !data) return <Redirect to="/" />;
	let content = <ProductDetails product={data} showSantander={showSantander} deviceType={deviceType} relatedCategories={relatedCategories}/>;
	return (
		<>
			<Modal>
				<ProductSingleWrapper>
					{loading ? (
						<SpinnerContainer>
							<Spinner></Spinner>
						</SpinnerContainer>
					) : (
						<ProductSingleContainer>
							{content}
							<CartPopUp deviceType={deviceType} />
						</ProductSingleContainer>
					)}
				</ProductSingleWrapper>
			</Modal>
		</>
	);
};
export async function getStaticProps({ params }) {
	const data = await getProductBySlug(params.slug);
	return {
		props: {
			data,
		},
	};
}
export async function getStaticPaths() {
	const products = await getAllProducts();
	return {
		paths: products.slice(0, 10).map(({ slug }) => ({ params: { slug } })),
		fallback: true,
	};
}
export default withNavbar(ProductPage);
