import NO_CHEMICALS from "./NO_CHEMICALS.svg";
import NO_GLUTEN from "./NO_GLUTEN.svg";
import NO_SUGAR from "./NO_SUGAR.svg";
import VEGAN from "./VEGAN.svg";
import NATURAL from "./NATURAL.svg";
import NO_LACTOSE from "./NO_LACTOSE.svg";
import NO_HYDROGENS from "./NO_HYDROGENS.svg";
import NO_SALT from "./NO_SALT.svg";

export const FOOD_CLASSIFICATION = {
	NO_CHEMICALS,
	NO_GLUTEN,
	NO_SUGAR,
	VEGAN,
	NATURAL,
	NO_LACTOSE,
	NO_HYDROGENS,
	NO_SALT
};
