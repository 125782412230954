export const SIEMPRE_PAGO_CODES = [
	{
		id: "TK001",
		message: "El número de tarjeta ingresado es incorrecto.",
	},
	{
		id: "TK002",
		message: "El número de CVV ingresado es incorrecto.",
	},
	{
		id: "TK003",
		message: "La fecha de vencimiento de la tarjeta es incorrecta.",
	},
	{
		id: "TK005",
		message: "Se ingresó un email con formato incorrecto.",
	},
	{
		id: "TK007",
		message: "El medio de pago no coincide con el esperado.",
	},
	{
		id: "TK008",
		message: "El banco emisor no coincide con el esperado.",
	},
	{
		id: "TK014",
		message: "Medio de pago deshabilitado.",
	},
	{
		id: "TK015",
		message: "El medio de pago no está disponible.",
	},
	{
		id: "PR009",
		message: "El estado actual de la compra no permite realizar la acción solicitada.",
	},
	{
		id: "CS007",
		message: "El Documento especificado es inválido.",
	},
	{
		id: "TR001",
		message: "Error de comunicación con el servicio adquirente.",
	},
	{
		id: "TR002",
		message: "La transacción asociada con la compra está en un estado que no permite la ejecución de la operación actual. Esto sucede, por ejemplo, cuando se desea realizar una operación de 'Commit' en una compra que ya está autorizada o que fue rechazada.",
	},
	{
		id: "TR003",
		message: "Problemas con la cuenta del comerciante en el adquirente.",
	},
	{
		id: "TR004",
		message: "Error al enviar la transacción al adquirente a través de Proxy.",
	},
	{
		id: "TR005",
		message: "Error interno del adquirente.",
	},
	{
		id: "TR006",
		message: "Número de orden duplicado en el adquirente.",
	},
	{
		id: "TR007",
		message: "Error con algún dato del medio de pago (número de tarjeta, código de verificación y/o fecha de expiración).",
	},
	{
		id: "TR008",
		message: "El monto que se intenta confirmar es mayor que el autorizado previamente.",
	},
	{
		id: "TR009",
		message: "Error desconocido del adquirente.",
	},
	{
		id: "TR010",
		message: "Documento de identidad inválido en el adquirente.",
	},
	{
		id: "TR011",
		message: "Tarjeta perdida o bloqueada.",
	},
	{
		id: "TR012",
		message: "Límite de crédito excedido.",
	},
	{
		id: "TR013",
		message: "El emisor o el adquirente rechazó la transacción.",
	},
	{
		id: "TR014",
		message: "El adquirente denegó la transacción por posible fraude.",
	},
	{
		id: "TR015",
		message: "El adquirente sugiere la revisión manual de la transacción. Por ejemplo, en caso de sospecha de fraude.",
	},
	{
		id: "TR016",
		message: "Error en los parámetros reportados al adquirente.",
	},
	{
		id: "TR017",
		message: "Tipo de transacción inválido.",
	},
	{
		id: "TR018",
		message: "El adquirente denegó el registro de la tarjeta.",
	},
	{
		id: "TR019",
		message: "Transacción rechazada por el adquiriente o procesador.",
	},
	{
		id: "TR020",
		message: "El emisor negó la transacción, sin embargo, se puede obtener autorización verbalmente.",
	},
	{
		id: "TR021",
		message: "La fecha de vencimiento o la tarjeta vencida no coinciden.",
	},
	{
		id: "TR022",
		message: "CVV erróneo.",
	},
	{
		id: "TR023",
		message: "La tarjeta está inactiva o no está autorizada para realizar este tipo de transacción.",
	},
	{
		id: "TR024",
		message: "Se ha excedido la frecuencia de uso o el monto máximo en un período.",
	},
	{
		id: "TR025",
		message: "Los datos de dirección no se pueden validar o son incorrectos.",
	},
	{
		id: "TR026",
		message: "Fondos insuficientes.",
	},
	{
		id: "TR075",
		message: "La respuesta de 3DSecure indica que se debe requerir la validación del cliente (VbV).",
	},
	{
		id: "TR076",
		message: "Falló la autenticación del pagador (VbV).",
	},
	{
		id: "TR100",
		message: "El adquirente rechazó por varias razones.",
	},
	{
		id: "TR101",
		message: "El adquirente no puede procesar el reembolso. Contacte al adquirente.",
	},
	{
		id: "TR301",
		message: "Rechazado por el sistema antifraude.",
	},
	{
		id: "TR302",
		message: "Parámetros inválidos para el sistema antifraude.",
	},
	{
		id: "TR997",
		message: "Ocurrió un error al procesar el pago.",
	},
	{
		id: "TR999",
		message: "Error indeterminado al ejecutar la transacción.",
	},
	{
		id: "ER999",
		message: "Error indeterminado.",
	},
];

export const SIEMPRE_PAGO_TRANSACTION_CODES = {
	APPROVED: 1,
	PENDING: 2,
	REJECTED: 4,
	CANCELLED: 6,
};
