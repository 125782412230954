import useSWR from "swr";

// import productFetcher from 'utils/api/product';
const fetcher = (url) => fetch(url).then((res) => res.json());
// interface CategoryProps {
//   type: string;
// }
export default function useCategory({ type }) {
	const { data, mutate, error } = useSWR(
		"https://nora-rey-backend.herokuapp.com/api/categories",
		fetcher
	);

	const loading = !data && !error;
	const categories =
		data && data.length
			? data
					?.filter((current) => current.type === type)
					.sort((a, b) => {
						if (a && b) {
							if (a.title == "Todos" || b.title == "Todos") {
								if (a.title == "Todos") {
									return -1;
								} else if (b.title == "Todos") {
									return 1;
								}
							} else {
								if (a.priority < b.priority || (a.priority && !b.priority)) {
									return -1;
								}
								if (a.priority > b.priority || (!a.priority && b.priority)) {
									return 1;
								}
								return 0;
							}
						} else {
							return 0;
						}
					})
			: [];
	// const paginatedData = data?.slice(offset, limit);
	// const loggedOut = error && error.status === 403;

	return {
		loadingCategories: loading,
		categoriesError: error,
		categories,
		// loggedOut,
		// user: data,
		mutate,
	};
}
