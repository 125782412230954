import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
const config = {
    apiKey: "AIzaSyDrJazjLcVY5t_FFLg8g2EgdOWLWYBWlIg",
    authDomain: "nora-rey.firebaseapp.com",
    databaseURL: "https://nora-rey.firebaseio.com",
    projectId: "nora-rey",
    storageBucket: "nora-rey.appspot.com",
    messagingSenderId: "861631332755",
    appId: "1:861631332755:web:93e2abca8414d58bf0a866",
    measurementId: "G-1FL21TC1MV"
};
if (!firebase.apps.length) {
    firebase.analytics(firebase.initializeApp(config));
    firebase.firestore().settings({ experimentalForceLongPolling: true })
}
const auth = firebase.auth();
auth.languageCode = 'es';
export {
    auth,
    firebase
};