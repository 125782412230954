import React from 'react';
export const Leaf = ({
  color = '#0D1137',
  width = '30px',
  height = '30px',
}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 469.824 469.824"  space="preserve" width={width} height={height}><g><g>
	<g>
		<path d="M188.885,67.36C147.84,26.336,67.52,21.579,10.667,21.579C4.779,21.579,0,26.357,0,32.245    c0,56.853,4.736,137.173,45.781,178.219c17.323,17.323,43.883,25.067,70.208,25.067c28.608,0,56.96-9.152,72.896-25.088    C219.456,179.872,225.067,103.52,188.885,67.36z M173.803,195.381c-22.827,22.805-86.208,26.709-112.917,0    C27.755,162.251,22.016,94.944,21.397,42.976c51.968,0.619,119.275,6.357,152.405,39.488    C200.512,109.173,196.608,172.576,173.803,195.381z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
	</g>
</g><g>
	<g>
		<path d="M459.157,63.776c-56.853,0-137.173,4.736-178.219,45.781c-36.181,36.16-30.571,112.512,0,143.083    c15.936,15.915,44.267,25.067,72.875,25.067c26.325,0,52.864-7.723,70.229-25.045c41.024-41.045,45.781-121.365,45.781-178.219    C469.824,68.555,465.045,63.776,459.157,63.776z M408.917,237.557c-26.709,26.709-90.112,22.805-112.917,0    c-22.805-22.805-26.709-86.208,0-112.917c33.131-33.131,100.437-38.869,152.405-39.488    C447.787,137.141,442.048,204.448,408.917,237.557z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
	</g>
</g><g>
	<g>
		<path d="M382.123,156.107c-2.517-5.355-9.728-7.189-15.04-4.715c-81.813,38.464-122.005,105.536-140.267,174.485    c-22.592-146.603-87.936-191.296-115.648-210.261c-3.456-2.368-6.144-4.096-7.637-5.568c-4.16-4.16-10.923-4.16-15.083,0    c-4.16,4.16-4.16,10.923,0,15.083c2.112,2.091,5.76,4.715,10.688,8.085c31.232,21.355,114.197,78.123,114.197,304.363    c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.645c0-91.413,24.555-211.925,141.504-266.88    c0-0.021,1.728-0.832,1.728-0.832C383.211,167.371,384.64,161.44,382.123,156.107z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
	</g>
</g></g> </svg>  );
};
