import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import RadioGroup from "../../components/radio-group/radio-group";
import RadioCard from "../../components/radio-card/radio-card";
import { useCart } from "../../contexts/cart/use-cart";

import { ProfileContext } from "../../contexts/profile/profile.context";
import { CardHeader } from "../../components/card-header/card-header";
import clock from "../../assets/images/notes/clock.svg";

// interface Props {
//   increment?: boolean;
// }

const Schedules = ({ increment = false, newSchedule, warningMessage }) => {
	const {
		state: { schedules },
		dispatch,
	} = useContext(ProfileContext);

	const { handleDelivery, delivery } = useCart();

	useEffect(() => {
		if (newSchedule) {
			dispatch({
				type: "SET_SCHEDULES",
				payload: newSchedule,
			});
		}
	}, []);

	const handleChange = (item) => {
		dispatch({
			type: "SET_PRIMARY_SCHEDULE",
			payload: item.id.toString(),
		});
		if (item.id == 2) {
			handleDelivery(true);
		} else {
			handleDelivery(false);
		}
	};	

	return (
		<>
			<CardHeader increment={increment}>
				<FormattedMessage
					id="deliverySchedule"
					defaultMessage="Select Your Delivery Schedule"
				/>
			</CardHeader>

			{warningMessage && (
				<div style={{ display: "flex" }}>
					<img style={{ height: "30px" }} src={clock} />

					<p
						style={{
							marginLeft: "10px",
							color: "#9abb51",
							fontSize: "14px",
							marginBottom: "20px",
						}}
					>
						{warningMessage}
					</p>
				</div>
			)}

			<RadioGroup
				items={schedules}
				component={(item) => (
					<RadioCard
						id={item.id}
						key={item.id}
						disabled={item.disabled}
						title={item.title}
						subtitle={item.subtitle}
						content={item.time_slot}
						name="schedule"
						checked={item.type === "primary"}
						withActionButtons={false}
						onChange={() => handleChange(item)}
					/>
				)}
			/>

		{/* <div style={{marginBottom: '10px'}}>
			<h6 style={{ color: "#ba5065", marginLeft: "10px" }}>Atención:</h6>
						<div style={{ display: "flex"}}>
							<p style={{ marginLeft: "10px", color: "#ba5065", fontSize: "14px" }}>
								Los pedidos realizados desde el<strong> 29/12/20</strong> hasta el{" "}
								<strong>02/01/21</strong> serán entregados a partir del <strong>05/01/21. <br></br> Se coordinará por teléfono. </strong>
								Gracias por la comprensión.
							</p>
						</div>
			</div> */}
			

			<div>
				<h6 style={{ color: "#424561", marginLeft: "10px" }}>
					Consideraciones:
				</h6>
				<div style={{ display: "flex" }}>
					<p style={{ marginLeft: "10px", color: "#424561", fontSize: "14px" }}>
						<strong>Delivery:</strong> Se hacen envíos de <strong>Lunes a Sábado. </strong>
						 Todos los pedidos realizados antes de las{" "}
						<strong>14hs</strong>, serán entregados en las próximas{" "}
						<strong>48hs</strong>. Luego de las 14.00 y en casos especiales que
						se especificará, la elaboración del producto puede demorar hasta{" "}
						<strong>72hs</strong>.
					</p>
				</div>

				<div style={{ display: "flex" }}>
					<p style={{ marginLeft: "10px", color: "#424561", fontSize: "14px" }}>
						<strong>Pick up:</strong> Todos los pedidos realizados antes de las{" "}
						<strong>14hs</strong>, se podrán retirar al siguiente día a partir
						de las <strong>11am</strong> en la Boutique, de lo contrario será a
						partir de <strong>48hs</strong>.
					</p>
				</div>

				<div style={{ display: "flex" }}>
					<p style={{ marginLeft: "10px", color: "#424561", fontSize: "14px" }}>
						<strong>*Nota:</strong> El tiempo de entrega <strong>puede variar</strong>, si usted
						necesita su producto antes de lo previsto, se coordinará por teléfono una vez
						concretada la compra.
					</p>
				</div>
			</div>
		</>
	);
};

export default Schedules;
