import React from 'react';
export const Pie = ({
  color = '#0D1137',
  width = '30px',
  height = '30px',
}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 382.498 382.498"  space="preserve" width={width} height={height}><g><g>
	<g>
		<path d="M372.65,229.378c0.023-0.346,0.023-0.694,0-1.04c-0.2-0.448-0.441-0.876-0.72-1.28l-0.72-0.96l-142.32-136    c-1.161-1.133-2.64-1.887-4.24-2.16c-12.195-1.702-24.487-2.611-36.8-2.72c-4.696-3.652-10.171-6.171-16-7.36    c-1.36-14.72,0.24-48,27.68-62.72c3.888-2.209,5.249-7.152,3.04-11.04c-2.209-3.888-7.152-5.249-11.04-3.04    c-35.6,19.28-37.92,59.44-37.12,76.72c-7.137,1.599-13.68,5.176-18.88,10.32c-58.48,8.8-101.68,34.88-125.12,75.68v0.4v0.48    c-0.4,0.937-0.617,1.942-0.64,2.96v142.88c-0.064,3.912,2.711,7.296,6.56,8l346.96,64h1.44c4.418,0,8-3.582,8-8v-143.36    c0,0,0,0,0-0.56C372.733,230.177,372.706,229.776,372.65,229.378z M163.69,92.498c12.725-0.044,23.076,10.235,23.12,22.96    c0.044,12.725-10.235,23.076-22.96,23.12c-12.725,0.044-23.076-10.235-23.12-22.96c0-0.027,0-0.053,0-0.08    C140.73,102.845,150.996,92.542,163.69,92.498z M126.01,105.938L126.01,105.938c-0.823,3.107-1.253,6.306-1.28,9.52    c-0.632,21.552,16.326,39.536,37.878,40.168c21.552,0.632,39.536-16.326,40.168-37.878c0.169-5.761-0.94-11.488-3.246-16.77    c9.04,0.48,16,1.28,19.2,1.68l121.44,116.08l-308.64-56.96C51.29,133.298,83.13,114.658,126.01,105.938z M26.01,303.778v-38.4    l330.96,59.6l0.08,39.92L26.01,303.778z M26.01,249.138v-24l330.96,59.6l0.08,24.16L26.01,249.138z M26.01,209.058v-32    l245.52,45.36l85.36,16l0.16,30.48L26.01,209.058z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/>
	</g>
</g></g> </svg>  );
};
