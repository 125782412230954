import React from 'react';
export const Dish = ({
  color = '#0D1137',
  width = '30px',
  height = '30px',
}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" id="icons" enable-background="new 0 0 64 64" height={height} viewBox="0 0 64 64" width={width}><g><path d="m2.999 26.358v25.655c0 1.379 1.121 2.5 2.5 2.5s2.5-1.121 2.5-2.5v-25.655c1.721-.447 3-2 3-3.858v-12.002c0-.553-.447-1-1-1s-1 .447-1 1v8h-1v-8c0-.553-.447-1-1-1s-1 .447-1 1v8h-1v-8c0-.553-.447-1-1-1s-1 .447-1 1v8h-1v-8c0-.553-.447-1-1-1s-1 .447-1 1v12.002c0 1.858 1.279 3.411 3 3.858zm3 25.656c0 .275-.225.5-.5.5s-.5-.225-.5-.5v-25.514h1zm-4-31.516h7v2.002c0 1.103-.897 2-2 2h-3c-1.103 0-2-.897-2-2z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/><path d="m56.998 9.488c-.553 0-1 .447-1 1 0 .006.003.01.003.015v.001 41.51c0 1.379 1.121 2.5 2.5 2.5s2.5-1.121 2.5-2.5v-17.506h2c.553 0 1-.447 1-1v-17.018c0-3.861-3.142-7.002-7.003-7.002zm2.003 42.526c0 .275-.225.5-.5.5s-.5-.225-.5-.5v-17.506h1zm3-19.506h-4v-20.918c2.279.466 4 2.486 4 4.9z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/><path d="m32.499 9.486c-12.406 0-22.5 10.094-22.5 22.5s10.094 22.5 22.5 22.5 22.5-10.094 22.5-22.5-10.094-22.5-22.5-22.5zm0 43c-11.304 0-20.5-9.196-20.5-20.5s9.196-20.5 20.5-20.5 20.5 9.196 20.5 20.5-9.196 20.5-20.5 20.5z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/><path d="m32.499 16.486c-8.547 0-15.5 6.953-15.5 15.5s6.953 15.5 15.5 15.5 15.5-6.953 15.5-15.5-6.953-15.5-15.5-15.5zm0 29c-7.444 0-13.5-6.056-13.5-13.5s6.056-13.5 13.5-13.5 13.5 6.056 13.5 13.5-6.056 13.5-13.5 13.5z" data-original="#000000" class="active-path" data-old_color="#000000" fill={color}/></g> </svg>
  );
};
