import React, { useEffect, useState } from 'react';
import useProducts from "../data/use-products";
import { CSVLink, CSVDownload } from "react-csv";

const FacebookCsv = () => {
    const [finalData, setFinalData] = useState(null);
    const { data, error, related, santanderActive, loading } = useProducts({
        type: 'grocery',
    });

    useEffect(() => {
        let normalizedData = [];
        if (data?.length) {
            normalizedData = [["id", "title", "description", "availability", "condition", "price", "link", "image_link", "brand"],
            ]
            data.forEach(element => normalizedData.push([`${element.id}`, `${element.title}`, `${element.description || "Producto"}`, `${element.quantity > 0 ? "in stock" : "out of stock"}`, "new", `${element.price} UYU`, `https://norareyboutiqueonline.com.uy/product/${element.slug}`, `${element.image}`, "Nora Rey"]))
            console.log(normalizedData, 'acaa')
            setFinalData(normalizedData)
        }
    }, [loading]);
    return (
        <>
            {
                !!finalData?.length && (
                    <CSVDownload data={finalData} target="_blank" />
                )
            }
        </>
    );
};

export default FacebookCsv;

