import React from 'react';
import { openModal } from '@redq/reuse-modal';
import { AuthContext } from '../../contexts/auth/auth.context';
import AuthenticationForm from '../../features/authentication-form';
import { RightMenu } from './menu/right-menu/right-menu';
import { LeftMenu } from './menu/left-menu/left-menu';
import HeaderWrapper from './header.style';
import LogoImage from '../../assets/images/logo/logoBoutique.png';
import UserImage from '../../assets/images/user.jpg';
import { isCategoryPage } from '../is-home-page';
import Search from '../../features/search/search';
import { useLocation, withRouter, useHistory } from "react-router-dom";
import {auth} from '../../firebase';
// type Props = {
//   className?: string;
// };

const Header = ({ className }) => {
  let history = useHistory();
  let location = useLocation();

  const {
    authState: { isAuthenticated },
    authDispatch,
  } = React.useContext(AuthContext);
  // const { pathname, query } = useRouter();
  const handleLogout = () => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('access_token');
      auth.signOut();
      authDispatch({ type: 'SIGN_OUT' });
      history.push('/');
    }
  };

  const handleJoin = () => {
    authDispatch({
      type: 'SIGNIN',
    });

    openModal({
      show: true,
      overlayClassName: 'quick-view-overlay',
      closeOnClickOutside: true,
      component: AuthenticationForm,
      closeComponent: '',
      config: {
        enableResizing: false,
        disableDragging: true,
        className: 'quick-view-modal',
        width: 458,
        height: 'auto',
      },
    });
  };
  const showSearch = location && location.pathname ? location.pathname.includes('grocery') || location.pathname.includes('category') || location.pathname.includes('text') || location.pathname === "/" : false
  return (
    <HeaderWrapper className={className} id="layout-header">
      <LeftMenu logo={LogoImage} />
      {showSearch && <Search minimal={true} className="headerSearch" />}
      <RightMenu
        isAuthenticated={isAuthenticated}
        onJoin={handleJoin}
        onLogout={handleLogout}
        avatar={UserImage}
      />
    </HeaderWrapper>
  );
};

export default Header;
