import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	CartPopupBody,
	PopupHeader,
	PopupItemCount,
	CloseButton,
	PromoCode,
	CheckoutButtonWrapper,
	CheckoutButton,
	Title,
	PriceBox,
	NoProductMsg,
	NoProductImg,
	ItemWrapper,
	CouponBoxWrapper,
	CouponCode,
} from "./cart.style";
import axios from "axios";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { ShoppingBagLarge } from "../../assets/icons/ShoppingBagLarge";
import { NoCartBag } from "../../assets/icons/NoCartBag";
import { CURRENCY } from "../../utils/constant";
import { FormattedMessage } from "react-intl";
import { useLocale } from "../../contexts/language/language.provider";

import { Scrollbars } from "react-custom-scrollbars";
import { useCart } from "../../contexts/cart/use-cart";
import { CartItem } from "../../components/cart-item/cart-item";
import Coupon from "../../features/coupon/coupon";
import { auth } from "../../firebase";

// type CartPropsType = {
//   style?: any;
//   className?: string;
//   scrollbarHeight?: string;
//   onCloseBtnClick?: (e: any) => void;
// };

const Cart = ({ style, className, onCloseBtnClick, scrollbarHeight }) => {
	const {
		items,
		coupon,
		addItem,
		removeItem,
		removeItemFromCart,
		cartItemsCount,
		calculatePrice,
	} = useCart();
	const [hasCoupon, setCoupon] = useState(false);
	const { isRtl } = useLocale();
	const [isLoggedIn, setIsLoggedIn] = useState(true);
	const [data, setData] = useState(null);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				auth.onAuthStateChanged(async function (user) {
					if (!user) {
						setLoading(false);
						setIsLoggedIn(false);
					}
				});
			} catch (error) {
				setError(error);
				setLoading(false);
				setIsLoggedIn(false);
			}
		};

		fetchData();
	}, []);

	return (
		<CartPopupBody className={className} style={style}>
			<PopupHeader>
				<PopupItemCount>
					<ShoppingBagLarge width="19px" height="24px" />
					<span>
						{cartItemsCount}
						&nbsp;
						{cartItemsCount > 1 ? (
							<FormattedMessage id="cartItems" defaultMessage="items" />
						) : (
							<FormattedMessage id="cartItem" defaultMessage="item" />
						)}
					</span>
				</PopupItemCount>

				<CloseButton onClick={onCloseBtnClick}>
					<CloseIcon />
				</CloseButton>
			</PopupHeader>

			<Scrollbars
				universal
				autoHide
				autoHeight
				autoHeightMax={scrollbarHeight}
				renderView={(props) => (
					<div
						{...props}
						style={{
							...props.style,
							marginLeft: isRtl ? props.style.marginRight : 0,
							marginRight: isRtl ? 0 : props.style.marginRight,
						}}
					/>
				)}
			>
				<ItemWrapper className="items-wrapper">
					{!!cartItemsCount ? (
						items.map((item) => (
							<CartItem
								key={`cartItem-${item.id}`}
								onIncrement={() => addItem(item)}
								onDecrement={() => removeItem(item)}
								onRemove={() => removeItemFromCart(item)}
								data={item}
							/>
						))
					) : (
						<>
							<NoProductImg>
								<NoCartBag />
							</NoProductImg>
							<NoProductMsg>
								<FormattedMessage
									id="noProductFound"
									defaultMessage="No products found"
								/>
							</NoProductMsg>
						</>
					)}
				</ItemWrapper>
			</Scrollbars>

			<CheckoutButtonWrapper>
				<PromoCode>
					{!coupon?.discountInPercent ? (
						<>
							{!hasCoupon ? (
								<button onClick={() => setCoupon((prev) => !prev)}>
									<FormattedMessage
										id="specialCode"
										defaultMessage="Have a special code?"
									/>
								</button>
							) : (
								<CouponBoxWrapper>
									<Coupon
										disabled={!items.length}
										style={{
											boxShadow: "0 3px 6px rgba(0, 0, 0, 0.06)",
										}}
									/>
								</CouponBoxWrapper>
							)}
						</>
					) : (
						<CouponCode>
							<FormattedMessage
								id="couponApplied"
								defaultMessage="Coupon Applied"
							/>
							<span>{coupon.code}</span>
						</CouponCode>
					)}
				</PromoCode>

				{isLoggedIn ? (
					cartItemsCount !== 0 ? (
						<Link to="/checkout">
							<CheckoutButton onClick={onCloseBtnClick}>
								<>
									<Title>
										<FormattedMessage
											id="nav.checkout"
											defaultMessage="Checkout"
										/>
									</Title>
									<PriceBox>
										{CURRENCY}
										{calculatePrice()}
									</PriceBox>
								</>
							</CheckoutButton>
						</Link>
					) : (
						<CheckoutButton>
							<>
								<Title>
									<FormattedMessage
										id="nav.checkout"
										defaultMessage="Checkout"
									/>
								</Title>
								<PriceBox>
									{CURRENCY}
									{calculatePrice()}
								</PriceBox>
							</>
						</CheckoutButton>
					)
				) : (
						<CheckoutButton onClick={onCloseBtnClick}>
							<>
								<Title>
									<FormattedMessage
										id="nav.go_to_sign_in"
										defaultMessage="Debes iniciar sesión"
									/>
								</Title>
							</>
						</CheckoutButton>
				)}
			</CheckoutButtonWrapper>
		</CartPopupBody>
	);
};

export default Cart;
