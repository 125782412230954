import React from "react";
import Table from "rc-table";
import {
	DeliveryInfo,
	DeliveryAddress,
	Address,
	CostCalculation,
	PriceRow,
	Price,
	ProgressWrapper,
	OrderTableWrapper,
	OrderTable,
} from "./order-details.style";
import Progress from "../../../../components/progress-box/progress-box";
import { CURRENCY } from "../../../../utils/constant";
import { FormattedMessage } from "react-intl";

// type OrderDetailsProps = {
//   tableData?: any;
//   columns?: any;
//   progressData?: any;
//   progressStatus?: any;
//   address?: string;
//   subtotal?: number;
//   discount?: number;
//   deliveryFee?: number;
//   grandTotal?: number;
// };

const components = {
	table: OrderTable,
};

const OrderDetails = ({
	tableData,
	columns,
	address,
	progressStatus,
	progressData,
	subtotal,
	discount,
	deliveryFee,
	deliveryMethod,
	grandTotal,
}) => {
	return (
		<>
			<DeliveryInfo>
				{deliveryMethod == 2 ? (
					<DeliveryAddress>
						<h3>
							<FormattedMessage
								id="deliveryLocationText"
								defaultMessage="Delivery Address"
							/>
						</h3>
						<Address>{address}</Address>
					</DeliveryAddress>
				) : deliveryMethod == 3 ? (
					<DeliveryAddress>
						<h3>Retiro en el local Pocitos</h3>
					</DeliveryAddress>
				) : (
					<DeliveryAddress>
					<h3>Retiro en el local Carrasco</h3>
				</DeliveryAddress>
				)}

				<CostCalculation>
					<PriceRow>
						<FormattedMessage id="subTotal" defaultMessage="Sub total" />
						<Price>
							{CURRENCY}
							{subtotal}
						</Price>
					</PriceRow>
					<PriceRow>
						<FormattedMessage
							id="intlOrderDetailsDiscount"
							defaultMessage="Discount"
						/>
						<Price>
							{CURRENCY}
							{discount}
						</Price>
					</PriceRow>
					<PriceRow>
						<FormattedMessage
							id="intlOrderDetailsDelivery"
							defaultMessage="Delivery Fee"
						/>
						<Price>
							{CURRENCY}
							{deliveryFee || "0.00"}
						</Price>
					</PriceRow>
					<PriceRow className="grandTotal">
						<FormattedMessage id="totalText" defaultMessage="Total" />
						<Price>
							{CURRENCY}
							{grandTotal}
						</Price>
					</PriceRow>
				</CostCalculation>
			</DeliveryInfo>

			<ProgressWrapper>
				<Progress data={progressData} status={progressStatus} />
			</ProgressWrapper>

			<OrderTableWrapper>
				<Table
					columns={columns}
					data={tableData}
					rowKey={(record) => record.id}
					components={components}
					className="orderDetailsTable"
					// scroll={{ y: 350 }}
				/>
			</OrderTableWrapper>
		</>
	);
};

export default OrderDetails;
