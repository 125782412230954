import React from "react";
import { createGlobalStyle } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { openModal, closeModal } from "@redq/reuse-modal";
import {
	CartPopupButtonStyled,
	ButtonImgBox,
	ItemCount,
	PriceBox,
	CartPopupBoxButton,
	PriceBoxAlt,
	TotalItems,
} from "./principal-menu.style";
import ReactTooltip from "react-tooltip";
import menu from "../../assets/images/logo/home-run.svg";

const PrincipalMenuStyle = createGlobalStyle`
  .cartPopup{
    top: auto !important;
    left: auto !important;
    bottom: 50px !important;
    right: 50px !important;
    box-shadow: ${themeGet("shadows.big", "0 21px 36px rgba(0, 0, 0, 0.16)")};
    transform-origin: bottom right;

    @media (max-width: 580px) {
      max-width: none!important;
      width: 100% !important;
      bottom: 0 !important;
      left: 0!important;
      background: ${themeGet("colors.white", "#ffffff")};
      overflow: initial !important;
      transform-origin: bottom center;
    }
  }
`;

// type CartProps = {
//   deviceType: {
//     mobile: boolean;
//     tablet: boolean;
//     desktop: boolean;
//   };
// };

const PrincipalMenu = ({ deviceType: { mobile, tablet, desktop } }) => {
	let cartSliderClass = "";

	return (
		<>
			{mobile ? (
				<>
					<PrincipalMenuStyle />
					{/* <CartPopupButton
						className="product-cart"
						itemCount={cartItemsCount}
						itemPostfix={
							cartItemsCount > 1 ? (
								<FormattedMessage id="cartItems" defaultMessage="items" />
							) : (
								<FormattedMessage id="cartItem" defaultMessage="item" />
							)
						}
						price={calculatePrice()}
						pricePrefix="$"
						onClick={handleModal}
					/> */}
				</>
			) : (
				<>
					<ReactTooltip html={true} />

					<CartPopupBoxButton style={{}} onClick={() => {}} className={""}>
						<PriceBoxAlt>
							<a
								href="https://norarey.com.uy/"
								data-tip="Volver al sitio informativo"
							>
								<img
									style={{ height: "32px" }}
									src={menu}
								></img>
							</a>
						</PriceBoxAlt>
					</CartPopupBoxButton>
				</>
			)}
		</>
	);
};

export default PrincipalMenu;
