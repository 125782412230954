export default [
  {
    id: '1',
    type: 'primary',
    title: 'Pick up en Carrasco',
    time_slot: '17/09: 11.00 AM - 8.00 PM',
  },
  {
    id: '3',
    type: 'secondary',
    title: 'Pick up en Pocitos',
    time_slot: '17/09: 11.00 AM - 8.00 PM',
  },
  {
    id: '2',
    type: 'secondary',
    title: 'Delivery',
    time_slot: '18/09: 10.00 AM - 16.00 PM',
  }
];
