import React from "react";
// import Router, { useRouter } from 'next/router';
import { FormattedMessage } from "react-intl";
import Logo from "../../../../layouts/logo/logo";
import { CATEGORY_MENU_ITEMS } from "../../../../site-settings/site-navigation";
import * as categoryMenuIcons from "../../../../assets/icons/category-menu-icons";

import {
	MainMenu,
	MenuItem,
	SelectedItem,
	Icon,
	Arrow,
	LeftMenuBox,
} from "./left-menu.style";

const CategoryIcon = ({ name }) => {
	const TagName = categoryMenuIcons[name];
	return !!TagName ? <TagName /> : <p>Invalid icon {name}</p>;
};

const CategoryMenu = (props) => {
	const handleOnClick = (item) => {
		if (item.dynamic) {
			// Router.push('/[type]', `${item.href}`);
			props.onClick(item);
			return;
		}
		// Router.push(`${item.href}`);
		props.onClick(item);
	};

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			{CATEGORY_MENU_ITEMS.map((item) => (
				<MenuItem key={item.id} {...props} onClick={() => handleOnClick(item)}>
					<CategoryIcon name={item.icon} />
					<FormattedMessage id={item.id} defaultMessage={item.defaultMessage} />
				</MenuItem>
			))}
		</div>
	);
};


export const LeftMenu = ({ logo }) => {

	return (
		<>
			<LeftMenuBox>
				<Logo
					imageUrl={logo}
					alt={"Shop Logo"}
					onClick={() => {}}
				/>

				<MainMenu></MainMenu>
			</LeftMenuBox>
		</>
	);
};
