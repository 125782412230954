import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from "react-toastify";
import RadioGroup from '../../components/radio-group/radio-group';
import RadioCard from '../../components/radio-card/radio-card';
import { Button } from '../../components/button/button';
import { handleModal } from '../../features/checkouts/checkout-modal';
import { ProfileContext } from '../../contexts/profile/profile.context';
import useUser from '../../data/use-user';
import CreateOrUpdateContact from '../../components/contact-card/contact-card';
import { CardHeader } from '../../components/card-header/card-header';
import { ButtonGroup } from '../../components/button-group/button-group';
import { Box } from '../../components/box';
import { Plus } from '../../assets/icons/PlusMinus';

const Contact = ({
  increment = false,
  flexStart = false,
  icon = false,
  buttonProps = {
    size: 'big',
    variant: 'outlined',
    type: 'button',
    className: 'add-button',
  },
}) => {
  const { deleteContactNumber } = useUser();

  const {
    state: { contact, id },
    dispatch,
  } = useContext(ProfileContext);

  const handleOnDelete = async (item) => {
    dispatch({ type: 'DELETE_CONTACT', payload: item.id });
    try {
      await deleteContactNumber(id);
      toast.success("Se ha eliminado su teléfono exitosamente", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
		} catch (e) {
      toast.error("Ha ocurrido un error, inténtelo nuevamente", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
		}
  };
  return (
    <>
      <CardHeader increment={increment}>
        <FormattedMessage
          id='contactNumberText'
          defaultMessage='Select Your Contact Number'
        />
      </CardHeader>
      <ButtonGroup flexStart={flexStart}>
        <RadioGroup
          items={contact}
          component={(item) => (
            <RadioCard
              id={item.id}
              key={item.id}
              content={item.number}
              checked={item.type === 'primary'}
              onChange={() =>
                dispatch({
                  type: 'SET_PRIMARY_CONTACT',
                  payload: item.id?.toString(),
                })
              }
              name='contact'
              onEdit={() => handleModal(CreateOrUpdateContact, item)}
              onDelete={() => handleOnDelete(item)}
            />
          )}
          secondaryComponent={
            <Button
              {...buttonProps}
              onClick={() =>
                handleModal(CreateOrUpdateContact, 'add-contact-modal')
              }
            >
              {icon && (
                <Box mr={2}>
                  <Plus width='10px' />
                </Box>
              )}
              <FormattedMessage
                id='addContactBtn'
                defaultMessage='Add Contact'
              />
            </Button>
          }
        />
      </ButtonGroup>
    </>
  );
};

export default Contact;
